import React, { useState, useEffect } from 'react';
import axios from 'axios';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import { useMonContexte } from '../../MonContext';

const ScheduleCalendar = () => {

    const {adresseServeurBackEnd, fichePersonnel} = useMonContexte();
    const [schedules, setSchedules] = useState([]);
    
    const fetchEdt = async() =>{
      try {
        const response = await axios.get(`${adresseServeurBackEnd}/employes`,
        {headers:{ Authorization: `Bearer ${fichePersonnel.token}`}
        });
        const events = response.data.map(schedule => ({
          title: `Arrivée: ${schedule.heure_arrivee}, Pause: ${schedule.heure_pause_debut} - ${schedule.heure_pause_fin}, Sortie: ${schedule.heure_sortie}`,
          start: schedule.date,
          backgroundColor: schedule.couleurEDT,
        }));
        setSchedules(events);
      } catch (error) {
        console.error('Erreur lors du chargement des EDT', error);
      }
    }
    useEffect(() => {
      fetchEdt();
    }, []);
  
    return (
      <FullCalendar
        plugins={[dayGridPlugin]}
        initialView="dayGridMonth"
        events={schedules}
      />
    );
  };

export default ScheduleCalendar