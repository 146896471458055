import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './modifClient.css';
import { useMonContexte } from '../../MonContext';
import FicheClient from './FicheClient';



const ModifClient = () => { 

  const {adresseServeurBackEnd, ficheClient, setContenuPageCentrale, fichePersonnel} =  useMonContexte();
  

  const [form, setForm] = useState(ficheClient);

    
  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({ ...prevForm, [name]: value }));
};

const handleSubmit = async (e) => {
  e.preventDefault();

  try {
    const formattedDate = new Date(form.dateNaissance).toISOString().split('T')[0]; // Format ISO 8601 YYYY-MM-DD
    const formData = { ...form, dateNaissance: formattedDate };
    if (form.id) {
      await axios.put(`${adresseServeurBackEnd}/clients/${form.id}`, formData,{
        headers: {
            Authorization: `Bearer ${fichePersonnel.token}`,
            'Content-Type': 'application/json'
        }
      });
    } else {
      await axios.post(`${adresseServeurBackEnd}/clients`, formData,{
        headers: {
            Authorization: `Bearer ${fichePersonnel.token}`,
            'Content-Type': 'application/json'
        }
      });

    }
    setForm({ id: null, nom: '', prenom: '', dateNaissance: '', telephone: '', adresse: '', codePostal: '', ville: '', mail: '' });
      
  } catch (error) {
    console.error('Erreur lors de l\'enregistrement du client', error);
  }
};


  return (
    <div className='bloc-modif-client'>
      <h1>Modification de la Fiche Client</h1>
     <form className="clients-form" onSubmit={handleSubmit}>
          <input
            type="text"
            name="nom"
            placeholder="Nom"
            value={form.nom}
            onChange={handleChange}
            required
          />
         <input
            type="text"
            name="prenom"
            placeholder="Prénom"
            value={form.prenom}
            onChange={handleChange}
          />
          <input
            type="date"
            name="dateNaissance"
            placeholder="Date de Naissance"
            value={form.dateNaissance}
            onChange={handleChange}
          />
          <input
            type="text"
            name="telephone"
            placeholder="Téléphone"
            value={form.telephone}
            onChange={handleChange}
          />
          <input
            type="text"
            name="adresse"
            placeholder="Adresse"
            value={form.adresse}
            onChange={handleChange}
          />
          <input
            type="text"
            name="codePostal"
            placeholder="Code Postal"
            value={form.codePostal}
            onChange={handleChange}
          />
          <input
            type="text"
            name="ville"
            placeholder="Ville"
            value={form.ville}
            onChange={handleChange}
          />
          <input
          type="text"
          name="mail"
          placeholder="Email"
          value={form.mail}
          onChange={handleChange}
        />
        <div>
            <button type="submit">{form.id ? 'Modifier' : 'Ajouter'}</button>
            <button onClick={()=> setContenuPageCentrale(<FicheClient/> )} >Annuler</button>
        </div>
        </form>
    </div>
  )
}

export default ModifClient