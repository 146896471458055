import React, { createContext, useContext, useState } from 'react'
import PageConnexion from './AppliWeb/PageTemplete/PageConnexion';
import AffichageClientSelectionnee from './AppliWeb/PageTemplete/AffichageClientSelectionnee';
import GestionClients from './AppliWeb/Client/GestionClients';


const MonContexte = createContext();

let ficheClientTempo = {
    id: 8,
    nom: 'TAYLOR',
    prenom: 'Alyson',
    dateNaissance: '16/06/2004', 
    telephone: '0692323575',
}

let ficheUserTempo = {
    id: 1,
    nom: 'Bennett',
    prenom: 'Gill',
    fonction: 'Manager',
    token: '',
}

let fichePresta = {
  id: 1, 
  nom: 'P-VSP Main', 
  description: 'Pose vernis  Couleur semi permanent', 
  duree: 30, 
  prix: '20.00'
}

let listePerson = {
  id: 1, 
  nom: 'TAYLOR', 
  prenom: 'Alyce'
}



let backend = 'https://apicoiffeur.maricourt.ovh'

const MonContext = ({ children }) => {

    const [pageConnexion, setPageConnexion] = useState(false);
    const [adresseServeurBackEnd, setAdresseServeurBackEnd] = useState(backend);
    const [infoConnexion, setInfoConnexion] = useState('Connectée');
    const [ficheClient, setFicheClient] = useState(ficheClientTempo);
    const [fichePersonnel, setFichePersonnel] = useState(ficheUserTempo);
    const [fichePrestation, setFichePrestation] = useState([fichePresta]);
    const [listePersonnel, setListePersonnel] = useState([listePerson]);
    const [objectifs, setObjectifs] = useState([]);
    const [encours, setEncours] = useState(150);
    const [basculeRDVClient, setBasculeRDVClient] = useState(false);
    const [memos, setMemos] = useState([]);
    const [nbreMessage, setNbreMessage] = useState(0);

    const [contenuPageCentrale, setContenuPageCentrale] = useState(<GestionClients/>);
    const [contenuPageDroite, setContenuPageDroite]= useState(<AffichageClientSelectionnee/>)
    const [affichage, setAffichage] = useState(<PageConnexion/>)


    const contextPartager = {
        pageConnexion, setPageConnexion, affichage, setAffichage, infoConnexion, setInfoConnexion, ficheClient,
        setFicheClient, fichePersonnel, setFichePersonnel, contenuPageCentrale, setContenuPageCentrale, 
        contenuPageDroite, setContenuPageDroite, adresseServeurBackEnd, setAdresseServeurBackEnd, 
        fichePrestation, setFichePrestation, listePersonnel, setListePersonnel, objectifs, setObjectifs, encours, 
        setEncours, basculeRDVClient, setBasculeRDVClient, memos, setMemos, nbreMessage, setNbreMessage
      }

  return (
    <MonContexte.Provider value={contextPartager}>
      {children}
    </MonContexte.Provider>
  )
}
export const useMonContexte = () => useContext(MonContexte);
export default MonContext

