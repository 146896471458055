import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './gestionClient.css';
import { useMonContexte } from '../../MonContext';
import AffichageClientSelectionnee from '../PageTemplete/AffichageClientSelectionnee';
import FicheClient from './FicheClient';

const GestionClients = () => {

  const {adresseServeurBackEnd, fichePersonnel, setFicheClient, setContenuPageDroite, setContenuPageCentrale} =  useMonContexte();

    const [clients, setClients] = useState([]);
    const [form, setForm] = useState({
      id: null,
      nom: '',
      prenom: '',
      dateNaissance: '',
      telephone: '',
      adresse: '',
      codePostal: '',
      ville: ''
    });
    const [error, setError] = useState('');
    const [visibleFormAjouter, setVisibleFormAjouter] = useState(false);
    const [visibleBtnSupprime, setVisibleBtnSupprime] = useState(false)

    const visibilityBtnSupp = () => {
      if ((fichePersonnel.fonction === 'Manager') || (fichePersonnel.fonction === 'Responsable')){
        setVisibleBtnSupprime(true)
      }
    }
  
    useEffect(() => {
      fetchClients();
      visibilityBtnSupp();
      setContenuPageDroite(<AffichageClientSelectionnee/> )
    }, []);
  
    const fetchClients = async () => {
      try {
        const response = await axios.get(`${adresseServeurBackEnd}/clients`,
          {headers:{ Authorization: `Bearer ${fichePersonnel.token}`}
        });
        if (Array.isArray(response.data)) {
          setClients(response.data);
        } else {
          setError('La réponse de l\'API n\'est pas un tableau');
          console.error('La réponse de l\'API n\'est pas un tableau', response.data);
        }
      } catch (error) {
        setError('Erreur lors du chargement des clients');
        console.error('Erreur lors du chargement des clients', error);
      }
    };
  
    const handleChange = (e) => {
        setError('');
        const { name, value } = e.target;
        setForm((prevForm) => ({ ...prevForm, [name]: value }));
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      setVisibleFormAjouter(false)
      try {
        const formattedDate = new Date(form.dateNaissance).toISOString().split('T')[0]; // Format ISO 8601 YYYY-MM-DD
        const formData = { ...form, dateNaissance: formattedDate };
        if (form.id) {
          await axios.put(`${adresseServeurBackEnd}/clients/${form.id}`, formData, {
            headers: {
                Authorization: `Bearer ${fichePersonnel.token}`,
                'Content-Type': 'application/json'
            }
          });
        } else {
          await axios.post(`${adresseServeurBackEnd}/clients`, formData, {
            headers: {
                Authorization: `Bearer ${fichePersonnel.token}`,
                'Content-Type': 'application/json'
            }
        });
        }
        setForm({ id: null, nom: '', prenom: '', dateNaissance: '', telephone: '', adresse: '', codePostal: '', ville: '', mail: '' });
      
        fetchClients();
      } catch (error) {
        setError('Erreur lors de l\'enregistrement du client');
        console.error('Erreur lors de l\'enregistrement du client', error);
      }
    };
  
    const handleEdit = (client) => {
        setVisibleFormAjouter(true)
        setForm(client);
        console.log(client)
    };
  
    const handleDelete = async (id) => {
      try {
        await axios.delete(`${adresseServeurBackEnd}/clients/${id}`, {
          headers: {
              Authorization: `Bearer ${fichePersonnel.token}`,
              'Content-Type': 'application/json'
          }
      });
        fetchClients();
      } catch (error) {
        setError('Erreur lors de la suppression du client');
        console.error('Erreur lors de la suppression du client', error);
      }
    };

    const handleFermeForm = () => {
      setVisibleFormAjouter(false);
    }

    const handleSelectionFicheClient = (client) => {
      setFicheClient(client);
      setContenuPageCentrale(<FicheClient/>)
    }
  
    return (
      <div className="clients-manager">
        <h1>Gestion des Clients</h1>
        {error && <p className="error">{error}</p>}
        {!visibleFormAjouter &&<div className='btn-ajouter-client'>
                <button onClick={()=>setVisibleFormAjouter(true)}>
                    Ajouter un client</button>
        </div>}
        {visibleFormAjouter && <div className='bloc-form-client'>        
            <form className="clients-form" onSubmit={handleSubmit}>
              <input
                type="text"
                name="nom"
                placeholder="Nom"
                value={form.nom}
                onChange={handleChange}
                required
              />
            <input
                type="text"
                name="prenom"
                placeholder="Prénom"
                value={form.prenom}
                onChange={handleChange}
                required
              />
              <input
                type="date"
                name="dateNaissance"
                placeholder="Date de Naissance"
                value={form.dateNaissance}
                onChange={handleChange}
              />
              <input
                type="text"
                name="telephone"
                placeholder="Téléphone"
                value={form.telephone}
                onChange={handleChange}
              />
              <input
                type="text"
                name="adresse"
                placeholder="Adresse"
                value={form.adresse}
                onChange={handleChange}
              />
              <input
                type="text"
                name="codePostal"
                placeholder="Code Postal"
                value={form.codePostal}
                onChange={handleChange}
              />
              <input
                type="text"
                name="ville"
                placeholder="Ville"
                value={form.ville}
                onChange={handleChange}
              />
              <input
              type="text"
              name="mail"
              placeholder="Email"
              value={form.mail}
              onChange={handleChange}
            />
            <div className='btn-modif-form-client'>
                <button type="submit">{form.id ? 'Modifier' : 'Ajouter'}</button>
                <button onClick={()=> handleFermeForm()} >Annuler</button>
              </div>
            </form>
        </div>}

        {!visibleFormAjouter && <div className='table-container-client'>
        <table className="clients-table">
          <thead>
            <tr>
              <th>Nom</th>
              <th>Prénom</th>
              <th>Date de Naissance</th>
              <th>Téléphone</th>
              <th>Adresse</th>
              <th>Code Postal</th>
              <th>Ville</th>
              <th>Email</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {clients.map((client) => (
              <tr key={client.id}>
                <td>{client.nom}</td>
                <td>{client.prenom}</td>
                <td>{new Date(client.dateNaissance).toLocaleDateString('fr-FR')}</td>
                <td>{client.telephone}</td>
                <td>{client.adresse}</td>
                <td>{client.codePostal}</td>
                <td>{client.ville}</td>
                <td>{client.mail}</td>
                <td className='btn-table-client' >
                  <button className="edit-button" onClick={() => handleSelectionFicheClient(client)}>Sélection</button>
                  <button className="edit-button" onClick={() => handleEdit(client)}>Modifier</button>
                 {visibleBtnSupprime && <button className="delete-button" onClick={() => handleDelete(client.id)}>Supprimer</button>}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>}
      </div>
    );
}

export default GestionClients