import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'moment/locale/fr';
import { useMonContexte } from '../../MonContext';
import'./historique.css'
import MemoClient from '../Memo/MemoClient';
import AjoutMemo from '../Memo/AjoutMemo';

const Historique = () => {
    const {adresseServeurBackEnd, encours, setEncours, ficheClient, fichePersonnel} =  useMonContexte();

  const [prestations, setPrestations] = useState([]);
  const [clientID, setClientID] = useState('');
  const [prestationID, setPrestationID] = useState('');
  const [choixPresta, setChoixPresta] = useState([]);
  const [prestaId, setPrestaId] = useState([]);
  const [error, setError] = useState('');
  const [prixTotal, setPrixTotal] = useState(0);
  const [dureeTotalePresta, setDureeTotalePresta] = useState(0);
  const [modePaiement, setModePaiement] = useState('');


  const [formData, setFormData] = useState( {
    clientId: '',
    employeId: '',
    prixTotal: 0,
    date:'',
    prestations: [],
    modePaiement:'',
  });

  const fetchPrestations = async () => {
    try {
      const response = await axios.get(`${adresseServeurBackEnd}/prestations`,{
        headers: {
          Authorization: `Bearer ${fichePersonnel.token}`}
      });
      setPrestations(response.data);
    } catch (error) {
      console.error('Erreur lors de la récupération des prestations:', error);
    }
  };

  function getCurrentMonthInFrench() {
    const now = new Date();
    const year = now.getFullYear();
    const monthIndex = 1 + now.getMonth();
    const mois = monthIndex.toString()
    return `${year}-0${mois}`;
  }
  const recuperationEncoursMois = async () => { 
    const anneeMois = getCurrentMonthInFrench();
    console.log(anneeMois)
    const response = await axios.get(`${adresseServeurBackEnd}/prestationclients/employe/${fichePersonnel.id}/mois/${anneeMois}`,{
      headers:{Authorization: `Bearer ${fichePersonnel.token}`}
    });
    setEncours(response.data.totalPrestations);
 };

  useEffect(() => {
    fetchPrestations();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formattedData = {
      ...formData,
      clientId: ficheClient.id,
      employeId: fichePersonnel.id,
      prixTotal: prixTotal
    };
    console.log(formattedData)

    try {
      const response = await axios.post(`${adresseServeurBackEnd}/prestationclients`, formattedData,{
        headers: {
              Authorization: `Bearer ${fichePersonnel.token}`,
              'Content-Type': 'application/json'
        }
      });
      setError('Les prestations ont été enregistrées');
      console.log('Prestation client ajoutée avec ID:', response.data.id);
    } catch (error) {
      console.error('Erreur lors de l\'ajout de la prestation client:', error);
    }
    recuperationEncoursMois(); //mise à jour de l'encours
    setDureeTotalePresta(0);
    setPrixTotal(0);
    setChoixPresta([]);
    setPrestaId([]);
  };

  const handleClick = async (prestation) => {
    setError('');
    const prestationData = {
      id: prestation.id,
      nom: prestation.nom,
      duree: prestation.duree,
      prix: prestation.prix
    };
    
    setChoixPresta([...choixPresta, prestationData]);    
    setPrixTotal(prixTotal + parseFloat(prestation.prix));
    const nouveauxIdsPrestations = [...prestaId, prestation.id];
    setPrestaId(nouveauxIdsPrestations);
    setFormData({
      ...formData,
      prixTotal: prixTotal,
      prestations: nouveauxIdsPrestations,
    })
  }

  const handleClickClear = () => {
    setError('');
    setDureeTotalePresta(0);
    setPrixTotal(0);
    setChoixPresta([]);
    setPrestaId([]);
  };

  const formatDateTimeLocal = (date) => {
    const pad = (num) => num.toString().padStart(2, '0');
    return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())}T${pad(date.getHours())}:${pad(date.getMinutes())}`;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value)
    setError('');
    if (name === 'dateHeure') {
      const dateHeure = new Date(value);
      setFormData({ ...formData, [name]: dateHeure });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };


  return (
  <>
    <div className='container-historique-client'>
        <h2>Enregistrer les prestations de {ficheClient.prenom}</h2>
        <form onSubmit={handleSubmit}> 
          <div className='bloc-prestation'>
            <p>Choisissez vos prestations:</p>
              <div className='bloc-date-presta-historique'>
              <div className='label-presta-choix'>
                  <label>
                    Date et Heure:
                    <input className='input-date-historique'
                      type="datetime-local"
                      name="date"
                      value={formatDateTimeLocal(new Date(formData.date))}
                      onChange={handleInputChange}
                    />
                  </label>
                </div>
                  <div>
                      <label className='label-presta'>              
                          <select id='select-historique' name="prestations" multiple value={formData.prestations} readOnly>
                            {prestations.map((prestation) => (
                              <option key={prestation.id} value={prestation.id} onClick={() => handleClick(prestation)}>
                                  {prestation.nom}
                              </option>
                            ))}
                          </select>
                      </label>
                    </div>  
                    <div className='choix-presta'>
                          <div className='choix-ul-li'>
                              <ul>
                                {choixPresta.map((choix, index) => (
                                  <li key={index}>
                                    {choix.nom} {choix.duree} min
                                  </li>
                                ))}
                              </ul>  
                          </div>                       
                    </div>
              </div>      
              
          </div>
          <div className='bloc-final-historique'>
              <div className='label-presta'>              
                <select id='select-mode-paiement' name="modePaiement" multiple value={formData.modePaiement}
                          onChange={handleInputChange} >
                    <option value='Carte Bancaire'>Carte Bancaire</option>
                    <option value='Espèce'>Espèce</option>
                    <option value='Chèque'>Chèque</option>
                </select>
              </div>
              <div className='choix-totaux'>
                  Prix total:<span>{prixTotal}€ </span>  
              </div>
              <div className='mode-paiement-historique'>
                  Mode Paiement: <span>{formData.modePaiement}</span>
              </div>
              <div className='btn-choix-presta-historique'>
                <button type="button" onClick={handleClickClear}>Annuler</button>
              </div>
            </div>                    
          <div className='btn-historique-presta'>
              <button type="submit">Enregistrer la prestation</button>
          </div>
        </form>
    </div>
  <div className='container-historique-client'>
  <h2>Ecrire un memo dans l'historique de {ficheClient.prenom}</h2>
    <AjoutMemo />
  </div>
  </>
  );
};

export default Historique

