import React from 'react';
import '../App.css';
import NavBar from './NavBar';
import photo1 from '../assets/barber.jpg'
import photo2 from '../assets/barber-dessin.jpg'
import photo3 from '../assets/coupe_peigne.jpg'
import photo4 from '../assets/coupe_tondeuse.jpg'
import photo5 from '../assets/shampoing.jpg'
import photo6 from '../assets/barber_rasoire.jpg'
import PiedPage from './PiedPage';


const Gallery = () => {
  return (
    <div className="gallery">
        <NavBar/>
        <div className="gallery-images">
          <div className='card-gallery-img'>
            <img src={photo1} alt="Réalisation 1"/>
          </div>
          <div className='card-gallery-img'>
            <img src={photo2} alt="Réalisation 2"/>
          </div>
          <div className='card-gallery-img'>
            <img src={photo3} alt="Réalisation 1"/>
          </div>
          <div className='card-gallery-img'>
            <img src={photo4} alt="Réalisation 2"/>
          </div>
          <div className='card-gallery-img'>
            <img src={photo5} alt="Réalisation 1"/>
          </div>
          <div className='card-gallery-img'>
            <img src={photo6} alt="Réalisation 2"/>
          </div>
        </div>
        <PiedPage/>
    </div>
  );
};

export default Gallery;
