
import React, { useEffect, useState } from 'react'
import { useMonContexte } from '../../MonContext'
import axios from 'axios';

const GestionFonction = ({handleBasculeGestionFonction}) => {

    const {adresseServeurBackEnd, fichePersonnel} = useMonContexte();

    const [roles, setRoles] = useState([]);
    const [listeFonction, setListeFonction] = useState([]);
    const [newRole, setNewRole] = useState({ role: '', client: 0, rendezVous: 0, 
            objectifs: 0, prestations: 0, personnels: 0 });
    const [error, setError] = useState('');

    const fetchRoles = async () => {
        try {
          const response = await axios.get(`${adresseServeurBackEnd}/menu-permissions`,
          {headers:{ Authorization: `Bearer ${fichePersonnel.token}`}
          });
          if (Array.isArray(response.data)) {
            setRoles(response.data);
          } else {
            throw new Error('La réponse de l\'API n\'est pas un tableau');
          }
        } catch (error) {
          setError('Erreur lors du chargement des rôles');
          console.error('Erreur lors du chargement des rôles', error);
        }
      };

    useEffect(() => {
        fetchRoles()
    },[])

    const handlePermissionChange = (role, field, value) => {
        const updatedRoles = roles.map((r) => (r.role === role ? { ...r, [field]: value } : r));
        setRoles(updatedRoles);
    };

    const handleUpdate = async (role) => {
        const roleToUpdate = roles.find((r) => r.role === role);
        try {
          await axios.put(`${adresseServeurBackEnd}/menu-permissions/${role}`, roleToUpdate, {
            headers: {
                Authorization: `Bearer ${fichePersonnel.token}`,
                'Content-Type': 'application/json'
            }
          });
          alert('Permissions mises à jour avec succès');
        } catch (error) {
          setError('Erreur lors de la mise à jour des permissions');
          console.error('Erreur lors de la mise à jour des permissions', error);
        }
    };

    

    const handleDelete = async (role) => {
        try {
        await axios.delete(`${adresseServeurBackEnd}/menu-permissions/${role}`, {
          headers: {
              Authorization: `Bearer ${fichePersonnel.token}`,
              'Content-Type': 'application/json'
          }
        });
        fetchRoles();
        } catch (error) {
        setError('Erreur lors de la suppression de la catégorie');
        console.error('Erreur lors de la suppression de la catégorie', error);
        }
    };

    

  return (
    <div>
       <div className='container-personnel-table'>
        <p>Modifier le rôle des Fonctions</p>
        <table className="fonction-personnel-table">
          <thead>
            <tr>
              <th>Fonction</th>
              <th>Gestion Client</th>
              <th>Gestion RDV</th>
              <th>Gestion Objectifs</th>
              <th>Gestion Prestations</th>
              <th>Gestion Personnel</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {roles.map((role) => (
              <tr key={role.id}>
                <td>{role.role}</td>
                <td>
                  <label>
                    <input
                      type="radio"
                      name={`client-${role.role}`}
                      checked={role.client === 1}
                      onChange={() => handlePermissionChange(role.role, 'client', 1)}
                    />
                    Oui
                  </label>
                  <label>
                    <input
                      type="radio"
                      name={`client-${role.role}`}
                      checked={role.client === 0}
                      onChange={() => handlePermissionChange(role.role, 'client', 0)}
                    />
                    Non
                  </label>
                </td>
                <td>
                  <label>
                    <input
                      type="radio"
                      name={`rendezVous-${role.role}`}
                      checked={role.rendezVous === 1}
                      onChange={() => handlePermissionChange(role.role, 'rendezVous', 1)}
                    />
                    Oui
                  </label>
                  <label>
                    <input
                      type="radio"
                      name={`rendezVous-${role.role}`}
                      checked={role.rendezVous === 0}
                      onChange={() => handlePermissionChange(role.role, 'rendezVous', 0)}
                    />
                    Non
                  </label>
                </td>
                <td>
                  <label>
                    <input
                      type="radio"
                      name={`objectifs-${role.role}`}
                      checked={role.objectifs === 1}
                      onChange={() => handlePermissionChange(role.role, 'objectifs', 1)}
                    />
                    Oui
                  </label>
                  <label>
                    <input
                      type="radio"
                      name={`objectifs-${role.role}`}
                      checked={role.objectifs === 0}
                      onChange={() => handlePermissionChange(role.role, 'objectifs', 0)}
                    />
                    Non
                  </label>
                </td>
                <td>
                  <label>
                    <input
                      type="radio"
                      name={`prestations-${role.role}`}
                      checked={role.prestations === 1}
                      onChange={() => handlePermissionChange(role.role, 'prestations', 1)}
                    />
                    Oui
                  </label>
                  <label>
                    <input
                      type="radio"
                      name={`prestations-${role.role}`}
                      checked={role.prestations === 0}
                      onChange={() => handlePermissionChange(role.role, 'prestations', 0)}
                    />
                    Non
                  </label>
                </td>
                <td>
                  {role.role === 'Manager' ? (
                    <label>
                      <input
                        type="radio"
                        name={`personnels-${role.role}`}
                        checked={role.personnels === 1}
                        readOnly
                      />
                      Oui
                    </label>
                  ) : (
                    <>
                      <label>
                        <input
                          type="radio"
                          name={`personnels-${role.role}`}
                          checked={role.personnels === 1}
                          onChange={() => handlePermissionChange(role.role, 'personnels', 1)}
                        />
                        Oui
                      </label>
                      <label>
                        <input
                          type="radio"
                          name={`personnels-${role.role}`}
                          checked={role.personnels === 0}
                          onChange={() => handlePermissionChange(role.role, 'personnels', 0)}
                        />
                        Non
                      </label>
                    </>
                  )}
                </td>
                <td>
                    <div className='btn-action-fonction'>
                        <button className='btn-action' onClick={() => handleUpdate(role.role)}>Modifier</button>
                        <button className='btn-action' id={role.role} onClick={() => handleDelete(role.role)} disabled={role.role === 'Manager'}>
                                Supprimer</button>
                    </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        </div>
        <div className='btn-gestion-fonction'>
          <button onClick={() => handleBasculeGestionFonction(false)} > Annuler</button>
        </div>
    </div>
  )
}

export default GestionFonction