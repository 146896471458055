import React, { useEffect } from 'react'
import { useMonContexte } from '../MonContext';
import PageCourante from './PageTemplete/PageCourante';
import PageConnexion from './PageTemplete/PageConnexion';

const HomeAppliWeb = () => {
    const {pageConnexion, affichage, setAffichage} =  useMonContexte();
    const fonctionConnexionPage = () =>{
        if (pageConnexion) {
            setAffichage(<PageCourante/>)
        } else {
          setAffichage(<PageConnexion/>)
        }
      } 
  
    useEffect(()=>{
      fonctionConnexionPage();
    },[pageConnexion])


  return (
    <div>{affichage}</div>
  )
}

export default HomeAppliWeb