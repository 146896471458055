
import React, { useEffect, useState } from 'react'
import './boiteComm.css'
import axios from 'axios';
import { useMonContexte } from '../../MonContext';
import Reception from './Reception';
import RedigerMessage from './RedigerMessage';
import BoiteEnvoie from './BoiteEnvoie';

const BoiteCommunication = () => {

    const { adresseServeurBackEnd, fichePersonnel, setNbreMessage } = useMonContexte();
    const [affichageGestionComm, setAffichageGestionComm] = useState(<Reception/>);
    const [visibleBtn, setVisibleBtn] = useState('Reception');

    const handleClickEnvoie = () => {
        setAffichageGestionComm(<BoiteEnvoie/>)
        setVisibleBtn('Envoie')
    }

    const handleClickRediger = () => {
        setAffichageGestionComm(<RedigerMessage setAffichageGestionComm={setAffichageGestionComm} 
            setVisibleBtn={setVisibleBtn} />)
        setVisibleBtn('Rediger')
    }

    const handleClickReception = () => {
        setAffichageGestionComm(<Reception/>)
        setVisibleBtn('Reception')
    }

    const checkNewMessages = async () => {
        try {
            const response = await axios.get(`${adresseServeurBackEnd}/newMessagesCount/${fichePersonnel.id}`,{
                headers:{Authorization: `Bearer ${fichePersonnel.token}`}
              });
            setNbreMessage(response.data.newMessagesCount);
            console.log(response.data.newMessagesCount)
        } catch (error) {
            console.error('Erreur lors de la vérification des nouveaux messages:', error);
        }
    };

    useEffect(() => {

        const interval = setInterval(checkNewMessages, 2000);

        return () => clearInterval(interval);
    }, []);    


    return (
        <div className='container-boite-comm'>
            <div className='bar-nav-comm'>
               {(visibleBtn !== 'Rediger') && <div className='btn-gestion-comm'>
                    <button onClick={() => handleClickRediger()}>Rédiger un Message</button>
                </div>}
                {(visibleBtn !== 'Reception') && <div className='btn-gestion-comm'>
                    <button onClick={() => handleClickReception()}>Boite de Réception</button>
                </div>}
                {(visibleBtn !== 'Envoie') && <div className='btn-gestion-comm'>
                    <button onClick={() => handleClickEnvoie()}>Boite d'Envoie</button>
                </div>}
            </div>
            {affichageGestionComm}
        </div>
    );
}

export default BoiteCommunication

