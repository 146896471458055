import React from 'react'
import '../App.css'
const PiedPage = () => {

  return (
    <div className='pied-page'>
        <div className='pied-page-titre'>Tiare BarberShop</div>
        <div className='pied-page-slogan'>Votre style, notre passion, 98709 Mahina Tahiti.</div>
        
    </div>
  )
}

export default PiedPage