import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useMonContexte } from '../../MonContext';
import ScheduleForm from './ScheduleForm';

const GestionEDT = ({setVisibleCalendar}) => {

    const {adresseServeurBackEnd, fichePersonnel} = useMonContexte();

    const [employees, setEmployees] = useState([]);
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const [schedules, setSchedules] = useState([]);

    const fetchEmploye = async() =>{
      try {
        const response = await axios.get(`${adresseServeurBackEnd}/employes`,
          {headers:{ Authorization: `Bearer ${fichePersonnel.token}`}
        });
        setEmployees(response.data);
      } catch (error) {
          console.error('Erreur lors de la vérification des nouveaux messages:', error);
      }
    }
  
    useEffect(() => {
     fetchEmploye()
    }, []);
  
    const fetchSchedules = async(employeId) => {
      try {
        const response = await axios.get(`${adresseServeurBackEnd}/emploiDuTemps/${employeId}`,
          {headers:{ Authorization: `Bearer ${fichePersonnel.token}`}
        });
        setEmployees(response.data);
      } catch (error) {
          console.error('Erreur lors de la vérification des nouveaux messages:', error);
      }
    };
  
    const handleEmployeeSelect = (employee) => {
      setSelectedEmployee(employee);
      fetchSchedules(employee.id);
    };
  
    const handleScheduleAdded = (newSchedule) => {
      setSchedules([...schedules, newSchedule]);
    };
  
    return (
      <div>
        <h1>Gestion des horaires</h1>
        <div>
          <label>Choisir un employé:</label>
          <select onChange={(e) => handleEmployeeSelect(employees.find(emp => emp.id === parseInt(e.target.value)))}>
            <option value="">Sélectionner un employé</option>
            {employees.map(employee => (
              <option key={employee.id} value={employee.id}>{employee.nom} {employee.prenom}</option>
            ))}
          </select>
        </div>
        {selectedEmployee && (
          <div>
            <h2>Ajouter un horaire pour {selectedEmployee.nom} {selectedEmployee.prenom}</h2>
            <ScheduleForm employeId={selectedEmployee.id} onScheduleAdded={handleScheduleAdded} setVisibleCalendar={setVisibleCalendar}  />
            <h3>Horaires existants</h3>
            <ul>
              {schedules.map(schedule => (
                <li key={schedule.id}>
                  Date: {schedule.date}, Arrivée: {schedule.heureArrivee}, Pause: {schedule.heurePauseDebut} - {schedule.heurePauseFin}, Sortie: {schedule.heureSortie}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    );
  };

export default GestionEDT