import React from 'react'
import logoBarber from '../assets/logobarber.png'
import './headerLogo.css'

const HearderLogo = () => {
    return (
        <div className='logo-barber'>
            <div className='card-logo-barber'>
                <img src={logoBarber} alt="Tiare BarberShop Logo" />
            </div>
        </div>
    )
}

export default HearderLogo