import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import ScheduleCalendar from './ScheduleCalendar';
import GestionEDT from './GestionEDT';

const GestonEdt = () => {
  const [visibleCalendar, setVisibleCalendar] = useState(true)

  
    return (
      <div>
        {visibleCalendar && <GestionEDT setVisibleCalendar={setVisibleCalendar} />}
        {!visibleCalendar && <ScheduleCalendar setVisibleCalendar={setVisibleCalendar} />}
      </div>
    );
}

export default GestonEdt