import axios from 'axios';
import React, { useState } from 'react'
import { useMonContexte } from '../../MonContext';

const AjouterFonctionPersonnel = ({fetchRoles, handleBasculeFormAjoutFonction, handleBasculeFormPersonnel}) => {

    const {adresseServeurBackEnd, fichePersonnel} = useMonContexte();

    
    const [newRole, setNewRole] = useState({ role: '', client: 0, rendezVous: 0, 
        objectifs: 0, prestations: 0, personnels: 0 });


    const handleNewRoleChange = (e) => {
            const { name, value } = e.target;
            setNewRole((prev) => ({ ...prev, [name]: value }));
    };

    const handleAddRole = async (e) => {
        e.preventDefault();
        try {
        await axios.post(`${adresseServeurBackEnd}/menu-permissions`, newRole, {
          headers: {
              Authorization: `Bearer ${fichePersonnel.token}`,
              'Content-Type': 'application/json'
          }
      });
        fetchRoles();
        setNewRole({ role: '', client: 0, rendezVous: 0, objectifs: 0, prestations: 0, personnels: 0 });
        } catch (error) {
        console.error('Erreur lors de l\'ajout de la nouvelle catégorie', error);
        }
        handleBasculeFormPersonnel(true);
    };

  return (
    <>
       <form className='ajout-fonction-form' onSubmit={handleAddRole}>
            <div className='bloc-ajout-fonction'>
                <h2>Ajouter une nouvelle catégorie de personnel</h2>
                 <input
                    id='saisi-fonction'
                    type="text"
                    name="role"
                    placeholder="Nom de la fonction"
                    value={newRole.role}
                    onChange={handleNewRoleChange}
                  />
          <table className='table-ajout-fonction'>
          <thead>
            <tr>
              <th>Menu</th>
              <th>Validé</th>
            </tr>
          </thead>
            <tbody>
              <tr>
              </tr>
              <tr>
                <td>
                  <label>
                    Gestion Client:
                  </label>
                </td>
                <td>
                  <label>                   
                    <input
                      type="radio"
                      name="client"
                      value={1}
                      checked={newRole.client === 1}
                      onChange={() => setNewRole((prev) => ({ ...prev, client: 1 }))}
                    />
                    Oui
                    <input
                      type="radio"
                      name="client"
                      value={0}
                      checked={newRole.client === 0}
                      onChange={() => setNewRole((prev) => ({ ...prev, client: 0 }))}
                    />
                    Non
                  </label>
                </td>
              </tr>
              <tr>
                    <td>
                        <label>
                            Gestion RDV:
                        </label>
                    </td> 
                    <td> 
                        <label>
                            <input
                            type="radio"
                            name="rendezVous"
                            value={1}
                            checked={newRole.rendezVous === 1}
                            onChange={() => setNewRole((prev) => ({ ...prev, rendezVous: 1 }))}
                            />
                            Oui
                            <input
                            type="radio"
                            name="rendezVous"
                            value={0}
                            checked={newRole.rendezVous === 0}
                            onChange={() => setNewRole((prev) => ({ ...prev, rendezVous: 0 }))}
                            />
                            Non
                        </label>
                    </td>
              </tr>
              <tr>
                    <td>
                        <label>
                            Gestion Objectifs:
                        </label>
                    </td>
                    <td>
                        <label>
                            <input
                            type="radio"
                            name="objectifs"
                            value={1}
                            checked={newRole.objectifs === 1}
                            onChange={() => setNewRole((prev) => ({ ...prev, objectifs: 1 }))}
                            />
                            Oui
                            <input
                            type="radio"
                            name="objectifs"
                            value={0}
                            checked={newRole.objectifs === 0}
                            onChange={() => setNewRole((prev) => ({ ...prev, objectifs: 0 }))}
                            />
                            Non
                        </label>
                    </td>
              </tr>
              <tr>
                    <td>
                        <label>
                            Gestion Prestations:
                        </label>
                    </td>
                    <td>
                        <label>
                            <input
                            type="radio"
                            name="prestations"
                            value={1}
                            checked={newRole.prestations === 1}
                            onChange={() => setNewRole((prev) => ({ ...prev, prestations: 1 }))}
                            />
                            Oui
                            <input
                            type="radio"
                            name="prestations"
                            value={0}
                            checked={newRole.prestations === 0}
                            onChange={() => setNewRole((prev) => ({ ...prev, prestations: 0 }))}
                            />
                            Non
                        </label>
                    </td>
              </tr>
              <tr>
                    <td>
                        <label>
                            Gestion Personnel:
                        </label>
                    </td>
                    <td>
                        <label>
                            <input
                            type="radio"
                            name="personnels"
                            value={1}
                            checked={newRole.personnels === 1}
                            onChange={() => setNewRole((prev) => ({ ...prev, personnels: 1 }))}
                            />
                            Oui
                            <input
                            type="radio"
                            name="personnels"
                            value={0}
                            checked={newRole.personnels === 0}
                            onChange={() => setNewRole((prev) => ({ ...prev, personnels: 0 }))}
                            />
                            Non
                        </label>
                    </td>
              </tr>
            </tbody>
          </table>
                <div className='btn-ajout-fonction-personnel'>
                  <button type="submit">Ajouter</button>
                  <button type="button" onClick={() => handleBasculeFormAjoutFonction(false)}>Annuler</button>
                </div>

          </div>
        </form>
       
         

    </>
  )
}

export default AjouterFonctionPersonnel