import React, { useEffect, useState } from 'react'
import './redigerMessage.css'
import axios from 'axios';
import { useMonContexte } from '../../MonContext';
import BoiteEnvoie from './BoiteEnvoie';

const RedigerMessage = ({setAffichageGestionComm, setVisibleBtn}) => {
    const { adresseServeurBackEnd, fichePersonnel } = useMonContexte();
    const [listEmploye, setListEmploye] = useState([]);
    const [listDestinataire, setListDestinataire] = useState([]);
    const [objetMessage, setObjetMessage] = useState('')
    const [message, setMessage] = useState('');

    const fetchListEmploye = async () => {
        try {
            const response = await axios.get(`${adresseServeurBackEnd}/employes`,{
                headers:{Authorization: `Bearer ${fichePersonnel.token}`}
            });
            setListEmploye(response.data);
        } catch (error) {
            console.error('Erreur lors de la récupération des employés:', error);
        }
    };

    useEffect(() => {
        fetchListEmploye();
    }, []);

    const handleClikEmploye = (employe) => {
        if (!listDestinataire.some(dest => dest.employeId === employe.id)) {
            setListDestinataire([...listDestinataire, {
                employeId: employe.id,
                prenomEmploye: employe.prenom
            }]);
        }
    };

    const handleSubmitMessage = async () => {
        if (message.trim() && listDestinataire.length) {
            try {
                const response = await axios.post(`${adresseServeurBackEnd}/message`, {
                    employeId: fichePersonnel.id, // Assuming fichePersonnel contains the current user's info
                    objetMessage: objetMessage,
                    textMessage: message,
                    destinataires: listDestinataire.map(dest => dest.employeId),
                    headers: {
                        Authorization: `Bearer ${fichePersonnel.token}`,
                        'Content-Type': 'application/json'
                    }
                });
                setMessage('');
                setListDestinataire([]);
                setVisibleBtn('Envoie')
                setAffichageGestionComm(<BoiteEnvoie/>)
            } catch (error) {
                console.error('Erreur lors de l\'envoi du message:', error);
            }
        }
    };

    

    return (
        <>
            <div className='btn-rediger-message'>
                <div className='titre'>
                    Destinataire du message:
                </div>
                <div className='bloc-select-destinataire'>
                    <select
                        className='elect-destinataire'
                        id='select-presta'
                        name="prestations"
                        multiple
                        value={listDestinataire.map(dest => dest.employeId)}
                        readOnly >
                            {listEmploye.map((employe) => (
                (fichePersonnel.id !== employe.id) && <option key={'empl' + employe.id} value={employe.id} onClick={() => handleClikEmploye(employe)}>
                                    <span>{employe.prenom}</span>
                                </option>
                            ))}
                    </select>
                
                    <div className='liste-destinataire'>
                        <ul>
                            {listDestinataire.map((destinataire) => (
                               <li key={'desti' + destinataire.employeId}> {destinataire.prenomEmploye} </li>
                            ))}
                        </ul>
                    </div>
                    <div className='btn-supprime-destinataire'>
                        <button onClick={() => setListDestinataire([])}>Supprimer les destinataires</button>
                    </div>
                </div>
                <div className='bloc-saisie-message'>
                    <label>Objet: </label>
                    <input className='input-objet-message' type='text' value={objetMessage} onChange={(e) => setObjetMessage(e.target.value)}/>
                    <textarea
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        placeholder="Écrire un message"
                    />
                </div>
                <div className='btn-envoie-message'>
                    <button onClick={handleSubmitMessage}>Envoyer le message</button>
                </div>
            </div>
            

        </>
    );
}

export default RedigerMessage

