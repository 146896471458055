import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './SiteWeb/Home';
import Presentation from './SiteWeb/Presentation';
import Service from './SiteWeb/Service';
import Gallery from './SiteWeb/Gallery';
import Contact from './SiteWeb/Contact';
import './App.css';
import MonContext from './MonContext';
import HomeAppliWeb from './AppliWeb/HomeAppliWeb';

const App = () => {
  return (    
      <div>
      <MonContext>
      <BrowserRouter>
      <Routes>       
          <Route path="/" element={<Home/>} />
          <Route path="/presentation" element={<Presentation/>} />
          <Route path="/service" element={<Service/>} />
          <Route path="/gallery" element={<Gallery/>} />
          <Route path="/contact" element={<Contact/>} />
          <Route path="/connexion" element={<HomeAppliWeb/>} />    
      </Routes>
    </BrowserRouter>
     </MonContext>
      </div>
  );
};

export default App;
