import React, { useState } from 'react';
import axios from 'axios';
import './searchClient.css';
import { useMonContexte } from '../../MonContext';
import FicheClient from '../Client/FicheClient';
import AffichageClientSelectionnee from '../PageTemplete/AffichageClientSelectionnee';

const SearchClient = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [ficheTempo, setFicheTempo] = useState([]);

    const { adresseServeurBackEnd, setFicheClient, setContenuPageCentrale, setContenuPageDroite, fichePersonnel } = useMonContexte();

    const handleChange = (e) => {
      setSearchTerm(e.target.value);
      console.log(e.target.value)
    };

    

    const handleSubmit = async (e) => {
      console.log('response.data');
        e.preventDefault();
        try {
            const response = await axios.get(`${adresseServeurBackEnd}/clients/search?term=${encodeURIComponent(searchTerm)}`,
                { headers:{Authorization: `Bearer ${fichePersonnel.token}`}
            });            
            setFicheTempo(response.data);
        } catch (error) {
          console.log('response.data');
            console.error('Error searching clients:', error);
            alert('An error occurred while searching for clients.');
        }
    };

    const handleSelectionClient = (client) => {
        setFicheClient(client);
        setContenuPageCentrale(<FicheClient/>);
        setContenuPageDroite(<AffichageClientSelectionnee ficheClient={client}/> )
    };

    return (
        <div className="client-search-search">
            <div className='bloc-form-search'>
                <form className='form-search' onSubmit={handleSubmit}>
                    <input
                        type="text"
                        placeholder="Rechercher par nom, date de naissance, téléphone ou email"
                        value={searchTerm}
                        onChange={handleChange}
                    />
                    <button type="submit">Rechercher</button>
                </form>
            </div>
            <div className="table-container">
                <table className="clients-table-search">
                    <thead>
                        <tr>
                            <th>Nom</th>
                            <th>Prénom</th>
                            <th>Date de Naissance</th>
                            <th>Téléphone</th>
                            <th>Email</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {ficheTempo.map((client) => (
                            <tr key={client.id}>
                                <td>{client.nom}</td>
                                <td>{client.prenom}</td>
                                <td>{new Date(client.dateNaissance).toLocaleDateString('fr-FR')}</td>
                                <td>{client.telephone}</td>
                                <td>{client.mail}</td>
                                <td>
                                    <button className="btn-select-search" onClick={() => handleSelectionClient(client)}>Sélectionner</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default SearchClient;


