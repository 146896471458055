import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useMonContexte } from '../../MonContext';
import './boiteEnvoie.css';

const BoiteEnvoie = () => {
    const { adresseServeurBackEnd, fichePersonnel } = useMonContexte();
    const [messages, setMessages] = useState([]);
    const [selectedMessage, setSelectedMessage] = useState(null);

    const fetchSentMessages = async () => {
        try {
            const response = await axios.get(`${adresseServeurBackEnd}/sent/${fichePersonnel.id}`,{
                headers:{Authorization: `Bearer ${fichePersonnel.token}`}
              });
            setMessages(response.data);
        } catch (error) {
            console.error('Erreur lors de la récupération des messages envoyés:', error);
        }
    };

    useEffect(() => {
        fetchSentMessages();
    }, []);

    const handleClickMessage = (message) => {
        setSelectedMessage(message);
    };

    return (
        <div className='container-boite-envoie'>
            <h2>Boîte d'Envoi</h2>
            <div className='liste-messages'>
                {messages.map((message) => (
                    <div key={message.id} className='message-item' 
                    onClick={() => handleClickMessage(message)}>
                    <div className='message-objet'>
                        {message.objetMessage}
                    </div>
                    <div className='message-info-date'>
                        {new Date(message.dateCreation).toLocaleString()} envoyé à 
                        <span>{message.destinataires}</span>
                    </div>
                </div>
                ))}
            </div>
            {selectedMessage && (
                <div className='message-details'>
                    <h3>Message envoyé le {new Date(selectedMessage.dateCreation).toLocaleString()}</h3>
                    <p>{selectedMessage.textMessage}</p>
                    <button onClick={() => setSelectedMessage(null)}>Fermer</button>
                </div>
            )}
        </div>
    );
};

export default BoiteEnvoie;
