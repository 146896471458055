import React from 'react'
import { QRCodeCanvas } from 'qrcode.react';
import { useMonContexte } from '../../MonContext';
import './qrcode.css'

const QrCode = () => {

  const {adresseServeurBackEnd, ficheClient, fichePersonnel } = useMonContexte();
  
  const qrValue = JSON.stringify({
    url: `${adresseServeurBackEnd}/upload/${ficheClient.id}`,
    //url: `http://192.168.1.8:5000/upload/${ficheClient.id}`,
    nom: ficheClient.nom,
    prenom: ficheClient.prenom,
  });
  
    return (
      <div className='qrcode' >
        <h3>QR Code de {ficheClient.prenom}: </h3>
        <QRCodeCanvas value={qrValue} size={96} />        
      </div>
    );
}

export default QrCode