import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useMonContexte } from '../../MonContext';
import Calendrier from '../Calendrier/Calendrier';
import GestionPrestations from '../Prestation/GestionPrestations';
import GestionEmployes from '../Personnel/GestionEmployes';
import GestionClients from '../Client/GestionClients';
import SearchClient from '../Client/SearchClient';
import GestionObjectifs from '../Objectifs/GestionObjectifs';
import SaisieRendezVous from '../RendezVous/SaisieRendezVous';
import logojnp from '../image/logojnp974_bleu.png';
import './menuFonction.css'
import HearderLogo from '../HearderLogo';

const MenuFonction = () => {
    const {adresseServeurBackEnd, fichePersonnel, setContenuPageDroite, setContenuPageCentrale, ficheClient } = useMonContexte();
    const [tableMenu, setTableMenu] = useState({});

    const convertTableMenu = (tableMenu) => {
        return {
            client: tableMenu.client === 1,
            rendezVous: tableMenu.rendezVous === 1,
            objectifs: tableMenu.objectifs === 1,
            prestations: tableMenu.prestations === 1,
            personnels: tableMenu.personnels === 1
        };
    }

   

    useEffect(() => {
        const fetchMenuPersonnel = async() =>{
            try { 
                const response = await axios.get(`${adresseServeurBackEnd}/menu-permissions/${fichePersonnel.fonction}`,
                {headers: {Authorization: `Bearer ${fichePersonnel.token}`}
                })
                const convertedMenu = convertTableMenu(response.data[0]);
                setTableMenu(convertedMenu);                 
            }  catch (error) {
                console.error('Erreur lors de la récupération des permissions de menu:', error);
            }
        }
        fetchMenuPersonnel();
    }, [fichePersonnel]);

    return (
        <div className="nav-side-gauche">
            <div className='bloc-menu-side-gauche'>
                {tableMenu.client && <fieldset>
                    <legend>Clients</legend>
                    <div className='btn-affichage-menu-fonction'>
                        <button onClick={() => setContenuPageCentrale(<GestionClients />)}>Gestion des Clients</button>
                        <button onClick={() => setContenuPageCentrale(<SearchClient />)}>Recherche un Client</button>
                    </div>

                </fieldset>}

                {tableMenu.rendezVous && <fieldset>
                    <legend>RDV</legend>
                    <div className='btn-affichage-menu-fonction'>
                        <button onClick={() => {setContenuPageCentrale(<Calendrier />);setContenuPageDroite(<HearderLogo />)}}>Calendrier</button>
                        <button  onClick={() => setContenuPageCentrale(<SaisieRendezVous />)}>Prendre RDV</button></div>
                </fieldset>}

                {tableMenu.objectifs && <fieldset>
                    <legend>Objectifs</legend>
                    <div className='btn-affichage-menu-fonction'>
                        <button  onClick={() => setContenuPageCentrale(<GestionObjectifs />)}>Gestion des objectifs</button>
                        </div>
                </fieldset>}

                {tableMenu.prestations && <fieldset>
                    <legend>Prestations</legend>
                    <div className='btn-affichage-menu-fonction'>
                        <button onClick={() => setContenuPageCentrale(<GestionPrestations />)}>Gestion des Prestations</button>
                        </div>
                </fieldset>}

                {tableMenu.personnels && <fieldset>
                    <legend>Personnels</legend>
                    <div className='btn-affichage-menu-fonction'>
                        <button onClick={() => setContenuPageCentrale(<GestionEmployes />)}>
                            Gestion des Personnels
                        </button>
                        </div>
                </fieldset>}
               
            </div>
          {  false && <div className='logo-jnp'>
                <img src={logojnp} alt='img' />
            </div>}
            
        </div>
    );
};

export default MenuFonction;
