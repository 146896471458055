import React from 'react'
import Modal from 'react-modal';

Modal.setAppElement('#root');
const ModalAskYesNo = ({titre, ask, fonctionYes, fonctionNo, nomModalIsOpen, setNomModalIsOpen}) => {

    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          color: 'black',
          fontSize: '1.1rem',
          textAlign: 'center'
        },        
    };

    const styleButton = {
        color: 'black',
        fontSize: '1.1rem',
        margin: '25px',
        width : '110px',
    }

    const styleDiv = {
        textAlign: 'center',
    }

    const questionYes = () =>{
        fonctionYes();
        setNomModalIsOpen(false)
    }

    const questionNo = () =>{
        fonctionNo();
        setNomModalIsOpen(false)
    }


  return (
     <Modal isOpen={nomModalIsOpen} onRequestClose={() => setNomModalIsOpen(false)} style={customStyles}>
                <h2 style={{color:'red'}}>{titre}</h2>
                <p style={{color:'black'}}>{ask}</p>
                <div style={styleDiv}>
                    <button style={styleButton} onClick={() => questionYes()}>Oui</button>
                    <button style={styleButton} onClick={() => questionNo()}>Non</button>
                </div>    
    </Modal>
  )
}

export default ModalAskYesNo