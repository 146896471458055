
import React, { useState } from 'react';
import './connexion.css'; // Importation du fichier CSS pour la stylisation
import { useMonContexte } from '../../MonContext';
import axios from 'axios';
import cle from '../image/icons8-cle.png'
import { useNavigate } from 'react-router-dom';

const Connexion = () => {

  const {adresseServeurBackEnd, setAdresseServeurBackEnd, setPageConnexion, pageConnexion, setFichePersonnel} =  useMonContexte();

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [adresseServeur, setAdresseServeur] = useState(adresseServeurBackEnd);
    const [confiServeur, setConfigServeur] = useState(false);

    const navigate = useNavigate();

  const handleVersSiteWeb = () =>{
    navigate('/');
  }
   
  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
    setError('');
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    setError('');
  };

  const handleAdresseServeurChange = (event) => {
    setAdresseServeur(event.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setAdresseServeurBackEnd(adresseServeur)
    
    try {
      const response = await axios.post(`${adresseServeurBackEnd}/login`, { username, password });
      setFichePersonnel(response.data.userData);
      console.log(`${adresseServeurBackEnd}/login`)
      console.log(response.data.userData)
      setPageConnexion(true);
    } catch (error) {
      console.log(error.code)
      // Vérifiez si l'erreur est liée à la lecture de 'data' et si 'error.response' est défini
      if (error.message === 'Network Error') {
        console.error('Erreur! Serveur indisponible');
        setError('Erreur! Serveur indisponible');
      } else if (error.response && error.response.data && error.response.data.message) {
        // Si l'API renvoie un message d'erreur
        console.error('Error:', error.response.data.message);
        setError(error.response.data.message);
      } else {
        // Pour d'autres types d'erreurs
        console.error('Error:', error.message);
        setError('Une erreur est survenue. Veuillez réessayer.');
      }
    }
    setUsername('');
    setPassword('');
  };

  return (
  <div className="login-container">    
    <h2>Connectez-vous</h2>
      <form onSubmit={handleSubmit}>
        <div className="input-group">
          <label htmlFor="username">Identifiant:</label>
          <input type="text"id="username" value={username} onChange={handleUsernameChange} required />
        </div>
        <div className="input-group">
          <label htmlFor="password">Mot de passe:</label>
          <input type="password" id="password" value={password} onChange={handlePasswordChange} required/>
        </div>
        <div className='btn-login'>
        {!confiServeur && <button id='btn-login-connexion' type="submit">Se connecter</button>}

        {confiServeur &&  <div className='adresse-input-connexion' >
          <label htmlFor="adresseServeur">Adresse du serveur backend:</label>
          <input type="text" id="adresseServeur" value={adresseServeur} onChange={handleAdresseServeurChange} />
        </div>}

          <div className='container-img-reglage'>
              <button onClick={() => setConfigServeur(!confiServeur)} > 
                <img className='img-connexion' src={cle} alt='img' />
              </button> 
              <button onClick={() => handleVersSiteWeb()}>
                  Annuler
              </button> 
          </div>
        </div>  
      </form>
      <p className='error-connexion'>{error}</p>  
    </div>
  );
};

export default Connexion

