import React, { useState } from 'react';
import axios from 'axios';
import { useMonContexte } from '../../MonContext';
import './gestionEdt.css'

const daysOfWeek = ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];

const ScheduleForm = ({ employeId, onScheduleAdded, setVisibleCalendar }) => {
    const {adresseServeurBackEnd, fichePersonnel} = useMonContexte();
    const [month, setMonth] = useState('current'); // 'current' for current month, 'next' for next month
    const [schedules, setSchedules] = useState(
      daysOfWeek.reduce((acc, day) => ({
        ...acc,
        [day]: { heureArrivee: '', heurePauseDebut: '', heurePauseFin: '', heureSortie: '' },
      }), {})
    );
  
    const handleChange = (day, field, value) => {
      setSchedules({
        ...schedules,
        [day]: { ...schedules[day], [field]: value }
      });
    };
  
    const handleSubmit = async(e) => {
      e.preventDefault();
      const currentDate = new Date();
      const monthToUse = month === 'current' ? currentDate.getMonth() : currentDate.getMonth() + 1;
      const yearToUse = currentDate.getFullYear();
  
      const newSchedules = daysOfWeek.map(day => {
        const dayIndex = daysOfWeek.indexOf(day) + 1;
        const date = new Date(yearToUse, monthToUse, dayIndex);
        return {
          employeId,
          date: date.toISOString().split('T')[0],
          heureArrivee: schedules[day].heureArrivee,
          heurePauseDebut: schedules[day].heurePauseDebut,
          heurePauseFin: schedules[day].heurePauseFin,
          heureSortie: schedules[day].heureSortie,
        };
      });
      
      try {
        const response =  await axios.post(`${adresseServeurBackEnd}/emploiDuTemps`, newSchedules, {
          headers: {
              Authorization: `Bearer ${fichePersonnel.token}`,
              'Content-Type': 'application/json'
          }
      });
        onScheduleAdded(newSchedules);
        // Reset form fields
        setSchedules(daysOfWeek.reduce((acc, day) => ({
          ...acc,
          [day]: { heureArrivee: '', heurePauseDebut: '', heurePauseFin: '', heureSortie: '' },
        }), {}));
        setMonth('current');
      } catch (error) {
        console.error('Error adding schedule:', error);
      }
      setVisibleCalendar(false);
    };
      
    
  
    return (
      <form onSubmit={handleSubmit}>
        <div>
          <label>Mois:</label>
          <select value={month} onChange={(e) => setMonth(e.target.value)} required>
            <option value="current">Mois en cours</option>
            <option value="next">Mois suivant</option>
          </select>
        </div>
        <div className="days-container">
          {daysOfWeek.map((day) => (
            <div className="day-block" key={day}>
              <h3>{day}</h3>
              <div>
                <label>Heure d'arrivée:</label>
                <input
                  type="time"
                  value={schedules[day].heureArrivee}
                  onChange={(e) => handleChange(day, 'heureArrivee', e.target.value)}
                  required
                />
              </div>
              <div>
                <label>Début de la pause:</label>
                <input
                  type="time"
                  value={schedules[day].heurePauseDebut}
                  onChange={(e) => handleChange(day, 'heurePauseDebut', e.target.value)}
                  required
                />
              </div>
              <div>
                <label>Fin de la pause:</label>
                <input
                  type="time"
                  value={schedules[day].heurePauseFin}
                  onChange={(e) => handleChange(day, 'heurePauseFin', e.target.value)}
                  required
                />
              </div>
              <div>
                <label>Heure de sortie:</label>
                <input
                  type="time"
                  value={schedules[day].heureSortie}
                  onChange={(e) => handleChange(day, 'heureSortie', e.target.value)}
                  required
                />
              </div>
            </div>
          ))}
        </div>
        <button type="submit">Ajouter les horaires</button>
      </form>
    );
};

export default ScheduleForm;






/*
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useMonContexte } from '../MonContext';

const ScheduleForm = ({ employeId, onScheduleAdded }) => {

    const {adresseServeurBackEnd} = useMonContexte();

  const [date, setDate] = useState('');
  const [heureArrivee, setHeureArrivee] = useState('');
  const [heurePauseDebut, setHeurePauseDebut] = useState('');
  const [heurePauseFin, setHeurePauseFin] = useState('');
  const [heureSortie, setHeureSortie] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    const newSchedule = {
      employeId,
      date,
      heureArrivee,
      heurePauseDebut,
      heurePauseFin,
      heureSortie,
    };

    axios.post(`${adresseServeurBackEnd}/emploiDuTemps`, newSchedule)
      .then(response => {
        onScheduleAdded(newSchedule); // Notify parent component about the new schedule
        // Reset form fields
        setDate('');
        setHeureArrivee('');
        setHeurePauseDebut('');
        setHeurePauseFin('');
        setHeureSortie('');
      })
      .catch(error => {
        console.error('Error adding schedule:', error);
      });
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <label>Date:</label>
        <input type="date" value={date} onChange={(e) => setDate(e.target.value)} required />
      </div>
      <div>
        <label>Heure d'arrivée:</label>
        <input type="time" value={heureArrivee} onChange={(e) => setHeureArrivee(e.target.value)} required />
      </div>
      <div>
        <label>Début de la pause méridienne:</label>
        <input type="time" value={heurePauseDebut} onChange={(e) => setHeurePauseDebut(e.target.value)} required />
      </div>
      <div>
        <label>Fin de la pause méridienne:</label>
        <input type="time" value={heurePauseFin} onChange={(e) => setHeurePauseFin(e.target.value)} required />
      </div>
      <div>
        <label>Heure de sortie:</label>
        <input type="time" value={heureSortie} onChange={(e) => setHeureSortie(e.target.value)} required />
      </div>
      <button type="submit">Ajouter l'horaire</button>
    </form>
  );
};

export default ScheduleForm
*/
