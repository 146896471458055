import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './memoClient.css';
import { useMonContexte } from '../../MonContext';

const AfficheMemo = () => {
    const {adresseServeurBackEnd, fichePersonnel, ficheClient, memos, setMemos} =  useMonContexte();

    const fetchMemos = async () => {
        try {
          const response = await axios.get(`${adresseServeurBackEnd}/memoClient/${ficheClient.id}`,{
            headers:{Authorization: `Bearer ${fichePersonnel.token}`}
          }); 
          setMemos(response.data);
        } catch (error) {
          console.error('Erreur lors de la récupération des mémos:', error);
        }
      };

    useEffect(() => {
        fetchMemos();
    }, [adresseServeurBackEnd]);  


  return (
    <div className='memo-affich'>  
        <div className="memos-list">
              {memos.map((memo) => (
                <div key={memo.id} className='div-memo-map' > 
                    <div key={memo.id} className='memo-item'>                      
                        <p className='prompt-memo-client' >Le {new Date(memo.dateHeureCreation).toLocaleDateString('fr-FR')} &nbsp;
                          {memo.employePrenom} a écrit: </p>
                        <p>{memo.memoText}</p>            
                    </div>
                  </div>  
                
              ))}
        </div> 
    </div>
  )
}

export default AfficheMemo