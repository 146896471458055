import React from 'react';
import Connexion from '../Connexion/Connexion';
import '../Connexion/connexion.css';
import { useMonContexte } from '../../MonContext';
import HearderLogo from '../HearderLogo';

const PageConnexion = () => {

  const {pageConnexion, setPageConnexion, affichage, setAffichage} =  useMonContexte();
  return (
    <div className='bloc-page-connexion' >
        <div className='header-connexion'>
            <HearderLogo/>
        </div>  
        <div>
            <Connexion/>
        </div>
    </div>
  )
}

export default PageConnexion