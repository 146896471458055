import React, { useEffect, useState } from 'react'
import { useMonContexte } from '../../MonContext';
import './ficheClient.css'
import axios from 'axios';
import AffichageClientSelectionnee from '../PageTemplete/AffichageClientSelectionnee';
import AfficheMemo from '../Memo/AfficheMemo';

const FicheClient = () => {

    const {adresseServeurBackEnd, ficheClient, fichePersonnel, setContenuPageDroite, setBasculeRDVClient} =  useMonContexte();

    const [historiqueRdv, SetHistoriqueRdv] = useState([])

    
  const fetchRdv = async () => {
    try {
      const response = await axios.get(`${adresseServeurBackEnd}/rendezvous/${ficheClient.id}`,
        {headers: {Authorization: `Bearer ${fichePersonnel.token}`}
      });
      SetHistoriqueRdv(response.data);
    } catch (error) {
      console.error('Erreur lors de la récupération des employés:', error);
    }
  };

  useEffect(() => {
    fetchRdv();
    setBasculeRDVClient(false);
    setContenuPageDroite(<AffichageClientSelectionnee ficheClient={ficheClient} />)
  }, [adresseServeurBackEnd]);


  return (
    <div className='bloc-fiche-cliente'>
        <div className="client-profile-ident">
                      <h1>Fiche de {ficheClient.prenom}</h1>
                        <p><strong>Nom:</strong> {ficheClient.nom}</p>
                        <p><strong>Prénom:</strong> {ficheClient.prenom}</p>
                        <p><strong>Date de Naissance:</strong> {new Date(ficheClient.dateNaissance).toLocaleDateString('fr-FR')}</p>
                        <p><strong>Téléphone:</strong> {ficheClient.telephone}</p>
                        <p><strong>Adresse:</strong> {ficheClient.adresse}</p>
                        <p><strong>Code Postal:</strong> {ficheClient.codePostal}</p>
                        <p><strong>Ville:</strong> {ficheClient.ville}</p>
                        <p><strong>Email:</strong> {ficheClient.mail}</p>
                                  
        </div>
        <div className='client-profile'>
            <h1>Historique des RDV de {ficheClient.prenom} </h1>
            <div className='bloc-client-details'>
                    <div className="client-details-historique">
                        <ul>
                            {historiqueRdv.map((rdv, index)=> (
                                <li key={index}>{new Date(rdv.dateHeure).toLocaleDateString('fr-FR')}  à  &nbsp; 
                                {new Date(rdv.dateHeure).toLocaleTimeString('fr-FR')} avec {rdv.employePrenom
                                }</li>
                            ))}
                        </ul>
                    </div>
            </div>
        </div>
        <div className='client-profile'>
          <h1>Memo de {ficheClient.prenom}</h1>
            <AfficheMemo/>
        </div>
        <div className='client-profile'>
            <h1>Les photos de {ficheClient.prenom}</h1>
        </div>
    </div>
  );
}

export default FicheClient
