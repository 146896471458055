
import React from 'react';
import '../App.css';
import NavBar from './NavBar';
import imgCoif2 from '../assets/coif2.jpg'
import imgBarber from '../assets/barber_rasoire.jpg'
import imgBarbeHomme from '../assets/barbe_homme.jpg'
import imgShampoing from '../assets/shampoing.jpg'
import PiedPage from './PiedPage';
const Service = () => {
  return (
    <div className="service">
        <NavBar/>
        <h2>Nos Services</h2>
        <div className='bloc-service'>
            <div className='bloc1-image'>
                  <img className='img-service' src={imgCoif2} alt='img-vernis' />
                  <p>Coupe degradée</p>
            </div>           
              
            <div className='bloc2-image'>
                  <img className='img-service' src={imgBarber} alt='img-vernis' />
                  <p>Rasage traditionnel</p>   
            </div>  
             
            <div className='bloc3-image'>
                  <img className='img-service' src={imgBarbeHomme} alt='img-vernis' />
                  <p>Barbe et moustache</p>
            </div> 
              
            <div className='bloc4-image'>
                  <img className='img-service' src={imgShampoing} alt='img-vernis' />
                  <p>Soins capillaires et traitements</p> 
            </div>            
        </div>
        <PiedPage/>
    </div>
  );
};

export default Service;
