import React, { useEffect, useState } from 'react'
import { useMonContexte } from '../../MonContext';
import axios from 'axios';
import './photoClient.css'
import Carousel from './Carousel';

const PhotoClient = () => {
    const {adresseServeurBackEnd, ficheClient, fichePersonnel} =  useMonContexte();

    const [photos, setPhotos] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchPhotos = async () => {
            try {
                const response = await axios.get(`${adresseServeurBackEnd}/photos/${ficheClient.id}`,
                  {headers: {Authorization: `Bearer ${fichePersonnel.token}`}
                  });
                setPhotos(response.data);
                console.log(response.data)
                setLoading(false);
            } catch (err) {
                console.error('Erreur lors de la récupération des photos:', err);
                setError('Erreur lors de la récupération des photos.');
                setLoading(false);
            }
        };
        fetchPhotos();
    }, [adresseServeurBackEnd, fichePersonnel.token,ficheClient.id]);
    
    if (loading) {
        return <p>Chargement des photos...</p>;
    }
    
    if (error) {
        return <p>{error}</p>;
    }
/*

{photos.length > 0 ? (
          photos.map((photo, index) => (
            <div key={index} >
              <img className='image-cliente'
                src={`${adresseServeurBackEnd}/${photo.file_path}`}
                alt={photo.file_name}
              />
              <p>{photo.created_at}</p>
            </div>
          ))
        ) : (
          <p>Aucune photo trouvée pour ce client.</p>
        )}

*/

  return (
    <div>
      <h2>Photos du client</h2>
      <div className='bloc-photo' >
        <Carousel images={photos} />
      </div>
    </div>
  );
  
}

export default PhotoClient