
import React, { useState, useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import frLocale from '@fullcalendar/core/locales/fr';
import axios from 'axios';
import SaisieRendezVous from '../RendezVous/SaisieRendezVous';
import './calendrier.css';
import { useMonContexte } from '../../MonContext';
import moment from 'moment';
import 'moment-timezone';
import InfoRdv from './InfoRdv';
import AffichageClientSelectionnee from '../PageTemplete/AffichageClientSelectionnee';

const Calendrier = () => {
  const {adresseServeurBackEnd, setContenuPageCentrale, fichePersonnel, 
          setContenuPageDroite,ficheClient } = useMonContexte();

  const [events, setEvents] = useState([]);

  useEffect(() => {
    fetchEvents();
  }, [adresseServeurBackEnd]); // Inclure personnelId dans les dépendances


  const fetchEvents = async () => {
    setEvents([]);
    
    try {
      const response = await axios.get(`${adresseServeurBackEnd}/rendezvous`,{
        headers:{Authorization: `Bearer ${fichePersonnel.token}`}
      });
      const formattedEvents = response.data.map(event => ({
        ...event,
        id: event.id,
        title: event.nomPrenom || 'Rendez-vous',
        start: moment(event.dateHeure).toISOString(),
        end: moment(event.heureFin).toISOString(),
        employeId: event.employeId,
        backgroundColor: event.employeCouleur,
        eventDisplay: 'auto',
      }));
      setEvents(formattedEvents);
    } catch (error) {
      console.error('Erreur lors de la récupération des événements:', error);
    }
  };


  const handleEventClick = ({ event }) => {
    console.log(event)
    setContenuPageDroite(<InfoRdv rendezVous={event._def.extendedProps} />);
    setContenuPageCentrale(<Calendrier/>)
  };


  const handleDateClick = (arg) => {
    console.log('arg Date Click: ',arg)
    const dateStr = moment(arg.dateStr).tz('Etc/UTC').format();
    setContenuPageCentrale(<SaisieRendezVous modeModification={false} dateStr={dateStr}/>);
    setContenuPageDroite(<AffichageClientSelectionnee ficheClient={ficheClient} />) 
  }

 

  return (
    <div className="calendar-container">
     <FullCalendar
        hiddenDays={[0]}
        locale={frLocale}
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        initialView='timeGridWeek'
        headerToolbar={{
          left: 'dayGridMonth,timeGridWeek,timeGridDay',
          center: 'title',
          right: 'prev,next today'
        }}
        events={events}
        eventClick={handleEventClick}
        dateClick={handleDateClick}
        timeZone="local"
        slotMinTime="07:00:00"  
        slotMaxTime="20:00:00"
      />
      
    </div>
  );
};

export default Calendrier;



