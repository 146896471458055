import React from 'react';
import '../App.css';
import NavBar from './NavBar';
import logo from '../assets/logobarber.png'
import imgCoif1 from '../assets/coif1.jpg';
import imgCoif3 from '../assets/coif3.jpg';
import PiedPage from './PiedPage';

const Home = () => {
  return (
    <div className="home">
        <NavBar/>
        <div className='logo-home'>
            <img src={logo} alt="Tiare BarberShop Logo" className="logo"/>
        </div>
        <div className='container-contenu'>
                    <div className='container-texte'>
                        <h1 className='paragraphe1'> Tiare BarberShop! Nous serons ravis de vous accueillir 
                                    dans notre univers dédié à la beauté des cheveux.
                        </h1>
                    </div>
                    <div className='container-bloc1'>           
                            <div className='bloc1-image'>
                                <img className='vernis' src={imgCoif1} alt='img-vernis' />
                            </div>  

                            <div className='bloc1-texte'>
                                <h1 className='paragraphe2'>Crée récemment, Tiare BarberShop!  est le fruit de la passion et de 
                                    l'expertise d'une équipe de professionnels chevronnés dans les soins capillaires. Forte de plus 
                                    de 12 années d'expérience pour certains, l'équipe de Tiare Barber Shop tient à offrir à ses clients une expérience 
                                    unique, alliant savoir-faire technique et service personnalisé.
                                </h1>
                            </div>  
                    </div>
                
                    <div className='container-bloc1'> 
                            <div className='bloc2-texte'>
                                <h1 className='paragraphe2'>Explorez notre site pour découvrir nos services, notre galerie inspirante et nos conseils beautés capillaires. 
                                    N'hésitez pas à nous contacter pour prendre rendez-vous ou poser vos questions.
                                </h1>
                            </div>

                            <div className='bloc2-image'>
                                <img className='outils' src={imgCoif3} alt='img-outils' />
                            </div> 
                    </div>
        </div>
      <PiedPage/>

    </div>
  );
};

export default Home;
