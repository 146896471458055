import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import './entete.css';
import alyceLogo from '../../assets/logobarber.png';
import { useMonContexte } from '../../MonContext';
import { FaPowerOff } from "react-icons/fa6";
import { BiBell } from "react-icons/bi";
import axios from 'axios';
import Calendrier from '../Calendrier/Calendrier';
import SearchClient from '../Client/SearchClient';
import BoiteCommunication from './BoiteCommunication';
import CalendrierPersonnel from '../Calendrier/CalendrierPersonnel'


const EnteteUser = () => {

  const {adresseServeurBackEnd, setPageConnexion, fichePersonnel, objectifs, nbreMessage, encours, 
        setEncours, setContenuPageCentrale} =  useMonContexte();

    const [objectifMensuel, setObjectifMensuel] = useState(150);
    const [balance, setBalance] = useState(0);

  const moisOptions = [
    'janvier', 'février', 'mars', 'avril', 'mai', 'juin',
    'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'
  ];

  function obtenirAnneeActuels() {  
    const dateActuelle = new Date();
    const annee = dateActuelle.getFullYear().toString();     
    return annee;
  };

  function obtenirMoisActuels() {  
    const dateActuelle = new Date();
    const mois = moisOptions[dateActuelle.getMonth()];    
    return mois;
  };

  function getCurrentMonthInFrench() {
    const now = new Date();
    const year = now.getFullYear();
    const monthIndex = 1 + now.getMonth();
    const mois = monthIndex.toString()
    return `${year}-0${mois}`;
  }

const mois = obtenirMoisActuels();
const annee = obtenirAnneeActuels(); 

  const calculateTotal = (valeurs) => {
    return valeurs.reduce((total, valeur) => total + parseFloat(valeur || 0), 0).toFixed(2);
  };

  const calculeObjectifs = async () => { 
      const response = await axios.get(`${adresseServeurBackEnd}/objectifsEmployes/${fichePersonnel.id}/${annee}/${mois}`,
      {headers: {Authorization: `Bearer ${fichePersonnel.token}`}
      });
      setObjectifMensuel(calculateTotal(response.data.valeurs));
      setBalance(encours - calculateTotal(response.data.valeurs))
  };

  const recuperationEncoursMois = async () => { 
    const anneeMois = getCurrentMonthInFrench();
    const response = await axios.get(`${adresseServeurBackEnd}/prestationclients/employe/${fichePersonnel.id}/mois/${anneeMois}`,
      {headers: {Authorization: `Bearer ${fichePersonnel.token}`}
    });
    setEncours(response.data.totalPrestations);
 };

  const deconnexion = () => {
    setContenuPageCentrale(<SearchClient/>);
    setPageConnexion(false);
  }

  const colorationBalance = () =>{
    let signe = '-';  
    if (balance >= 0) {
        signe = '+';
    }
    return signe;
  }


  useEffect(() => {
    calculeObjectifs();
    recuperationEncoursMois();
  }, [objectifs]);

  useEffect(() => {
      calculeObjectifs();
  }, [encours]);

  return (
    <div className='navigation-entete'> 
      <div className='bloc-logo-entete'>
          <img className='logo-alyce' src={alyceLogo} alt='img'/>
      </div>
      <nav className='navbar-entete'> 
         <div className='bloc-ul-entete'>     
          <ul className="nav-menu-entete">
            <li className="nav-item-entete"><span className='personnel-entete'> {fichePersonnel.nom} &nbsp; {fichePersonnel.prenom} </span> </li>
            <li className="nav-item-entete">Objectif Mensuel :<span className='objectif-entete'>{objectifMensuel} € </span> </li>
            <li className="nav-item-entete">Encours :<span className='color-bleu'>{encours} € </span> </li>
            <li className="nav-item-entete">balance :<span className={balance > 0 ? 'color-vert':'color-rouge'}>{colorationBalance()}{Math.abs(balance)} € </span> </li>
            <li className="nav-item-entete" id='calendrier-entete' onClick={()=>setContenuPageCentrale(<CalendrierPersonnel />)}>Mon Calendrier</li>
          </ul>
        </div> 
      </nav>
      <div className='bloc-btn-entete'>
          <div className='btn-entete'>
              <BiBell className='icon-navigation' onClick={()=>setContenuPageCentrale(<BoiteCommunication /> )} />
              <span className='notification-message'>
                  {nbreMessage}
              </span>
          </div>
          <div className='btn-entete'>
              <FaPowerOff className='icon-navigation' onClick={() => deconnexion()}/>
          </div>     
      </div>
    </div>
  )
}

export default EnteteUser