import React from 'react'
import { useMonContexte } from '../../MonContext';
import FicheClient from '../Client/FicheClient';
import './affichageClientSelection.css'
import ModifClient from '../Client/ModifClient';
import MemoClient from '../Memo/MemoClient';
import Historique from '../Historique/Historique';
import PriseRDVClient from '../RendezVous/PriseRDVClient';
import ModifMemoClient from '../Memo/ModifMemoClient';
import QrCode from '../QrCode/QrCode';
import PhotoClient from '../Photo/PhotoClient';

const AffichageClientSelectionnee = () => {


    const {setContenuPageCentrale, ficheClient} =  useMonContexte();


  return (
    <div className='menu-client-selectionne'>
    {ficheClient && <div className="bloc-affich-client">
         <div className='text-fonction-menu'>
            Cliente:
        </div>
        <div className='bloc-fiche-ident'>                
                <div className='champ-affichage-selection-client'>
                    Nom: <span>{ficheClient.nom}</span>
                </div>
                <div className='champ-affichage-selection-client'>
                    Prénom: <span>{ficheClient.prenom}</span>
                </div>
                <div className='champ-affichage-selection-client'>
                    Téléphone:<span>{ficheClient.telephone}</span> 
                </div>
        </div>
        <div className='bloc-btn-gestion-client'>    
            <div className='btn-affichage-selection-client'>
                <button onClick={()=>setContenuPageCentrale(<FicheClient/>)}>Visualiser la Fiche</button>
            </div>
            <div className='btn-affichage-selection-client'>
                <button onClick={()=>setContenuPageCentrale(<Historique/>)}>Enregistrer une Prestation</button>
            </div>
            <div className='btn-affichage-selection-client'>
                <button onClick={()=>setContenuPageCentrale(<ModifClient />)}>Modifier la Fiche Cliente</button>
            </div>
            <div className='btn-affichage-selection-client'>
                <button onClick={()=>setContenuPageCentrale(<PhotoClient/>)}>Les photos</button>
            </div>
            <div className='btn-affichage-selection-client'>
                <button onClick={()=>setContenuPageCentrale(<ModifMemoClient/>)}>Modifier les Memos</button>
            </div>
            <div className='btn-affichage-selection-client'>
                <button onClick={()=>setContenuPageCentrale(<PriseRDVClient/>)}>Prendre un RDV</button>
            </div>
            <QrCode/>
        </div>
    </div>} 
    </div>
  )
}

export default AffichageClientSelectionnee