import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './memoClient.css';
import { useMonContexte } from '../../MonContext';

const AjoutMemo = () => {
    const {adresseServeurBackEnd, fichePersonnel, ficheClient, setMemos} =  useMonContexte();

    const [newMemoText, setNewMemoText] = useState('');

    const fetchMemos = async () => {
        try {
          const response = await axios.get(`${adresseServeurBackEnd}/memoClient/${ficheClient.id}`,{
            headers:{Authorization: `Bearer ${fichePersonnel.token}`}
          }); 
          setMemos(response.data);
          console.log(response.data)
        } catch (error) {
          console.error('Erreur lors de la récupération des mémos:', error);
        }
      };

    useEffect(() => {
        fetchMemos();
    }, [adresseServeurBackEnd]);  

    const handleAddMemo = async () => {
        try {
          await axios.post(`${adresseServeurBackEnd}/memoClient`, {
            clientId: ficheClient.id, // Remplacez par l'ID du client
            employeId: fichePersonnel.id, // Remplacez par l'ID de l'employé
            memoText: newMemoText},{
              headers: {
                  Authorization: `Bearer ${fichePersonnel.token}`,
                  'Content-Type': 'application/json'
              }
          });
          setNewMemoText('');
          fetchMemos();
        } catch (error) {
          console.error('Erreur lors de l\'ajout du mémo:', error);
        }
      };
   





  return (
    <div className='memo-manager'>    
      <div className='add-memo'>
        <textarea
          value={newMemoText}
          onChange={(e) => setNewMemoText(e.target.value)}
          placeholder="Écrivez un nouveau mémo..."
        />
        <button onClick={handleAddMemo}>Ajouter</button>
      </div>
   
    </div>
  )
}

export default AjoutMemo