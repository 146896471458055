import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './modifMemo.css';
import { useMonContexte } from '../../MonContext';
import ModalAskYesNo from '../../ModalAskYesNo';

const ModifMemoClient = () => {
    const {adresseServeurBackEnd, fichePersonnel, ficheClient} =  useMonContexte();

    const [memos, setMemos] = useState([]);
    const [memoId, setMemoId] = useState(null);
    const [editMemoText, setEditMemoText] = useState('');
    const [modeEditionMemo, setModeEditionMemo] = useState(false);
    const [suppressionMemoModalIsOpen, setSuppressionMemoModalIsOpen] = useState(false)

    const fetchMemos = async () => {
        try {
          const response = await axios.get(`${adresseServeurBackEnd}/memoClient/${ficheClient.id}`, {
            headers: { Authorization: `Bearer ${fichePersonnel.token}` }
          }); 
          setMemos(response.data);
          console.log(response.data)
        } catch (error) {
          console.error('Erreur lors de la récupération des mémos:', error);
        }
      };

    useEffect(() => {
        fetchMemos();
    }, [adresseServeurBackEnd]);  

        
    const fonctionYesSupprimeMemo = async (memoId) => {
        try {
          await axios.delete(`${adresseServeurBackEnd}/memo/${memoId}`, {
            headers: {
                Authorization: `Bearer ${fichePersonnel.token}`,
                'Content-Type': 'application/json'
            }
        });
          fetchMemos();
        } catch (error) {
          console.error('Erreur lors de la suppression du mémo:', error);
        }
    };

    const fonctionNo = () =>{
      setModeEditionMemo(false)
  } 


    const handleModeEdition =  (memoText, memoId) => {
      console.log(memoText, memoId)
         setModeEditionMemo(true)
         setEditMemoText(memoText)
         setMemoId(memoId)
    }

    const handleSauvegardeMemo = async (memoId) => {
        try {
          await axios.put(`${adresseServeurBackEnd}/memo/${memoId}`, {
            memoText: editMemoText,
          });
          setMemoId(null);
          setEditMemoText('');
          fetchMemos();
          setModeEditionMemo(false);
        } catch (error) {
          console.error('Erreur lors de la modification du mémo:', error);
        }
      };

  return (
    <div className='bloc-memo-modif'> 
      { !modeEditionMemo && <div className='list-memo-modif'>
              <h2>Liste des Mémos de {ficheClient.prenom} </h2>
              {memos.map((memo) => (
                <div key={memo.id} className='div-memo-modif-map' > 
                    <div className='memo-modif-item'>                      
                        <p className='prompt-memo-modif'>Le {new Date(memo.dateHeureCreation).toLocaleDateString('fr-FR')} &nbsp;{memo.employePrenom} a écrit: </p>
                        <p>{memo.memoText}</p>            
                    </div>
                    <div className='btn-modif-supp'>
                        <button onClick={() => handleModeEdition(memo.memoText, memo.memoId) } >Editer</button>
                        <button onClick={() => setSuppressionMemoModalIsOpen(true)} >Supprimer</button> 
                    </div>
                </div>  
                
              ))}
        </div>}

      { modeEditionMemo && <div className='edit-memo'>
        <textarea
          value={editMemoText}
          onChange={(e) => setEditMemoText(e.target.value)}
          placeholder="Écrivez un nouveau mémo..."
        />
        <div className='btn-edit-Modi-annul-memo'> 
            <button onClick={()=> handleSauvegardeMemo(memoId)}>Modifier</button>
            <button onClick={()=> setModeEditionMemo(false)}>Annuler</button>
        </div>
      </div>}
      <ModalAskYesNo titre={'Supprimer Memo'} ask={`Voulez-vous supprimer le memo de ${ficheClient.prenom}?` } 
          fonctionYes={fonctionYesSupprimeMemo} fonctionNo={fonctionNo} nomModalIsOpen={suppressionMemoModalIsOpen} 
          setNomModalIsOpen={setSuppressionMemoModalIsOpen} />
   
    </div>
  )
}

export default ModifMemoClient