
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useMonContexte } from '../../MonContext';
import './reception.css';

const Reception = () => {
    const { adresseServeurBackEnd, fichePersonnel, nbreMessage, setNbreMessage } = useMonContexte();
    const [messages, setMessages] = useState([]);
    const [selectedMessage, setSelectedMessage] = useState(null);



    const fetchMessages = async () => {
        try {
            const response = await axios.get(`${adresseServeurBackEnd}/message/${fichePersonnel.id}`,{
                headers:{Authorization: `Bearer ${fichePersonnel.token}`}
              });
            setMessages(response.data);
        } catch (error) {
            console.error('Erreur lors de la récupération des messages:', error);
        }
    };

    const markMessageAsRead = async (messageId) => {
        try {
            await axios.post(`${adresseServeurBackEnd}/message/markAsRead`, {
                employeId: fichePersonnel.id,
                messageId,
                headers: {
                    Authorization: `Bearer ${fichePersonnel.token}`,
                    'Content-Type': 'application/json'
                }
            });
        } catch (error) {
            console.error('Erreur lors de la mise à jour du statut de lecture du message:', error);
        }
        fetchMessages();
    };

    useEffect(() => {
        fetchMessages();
    }, []);


    const handleClickMessage = async(message) => {
        setSelectedMessage(message);
        if (!message.lu) {
            markMessageAsRead(message.id);
            setMessages(messages.map(msg => msg.id === message.id ? { ...msg, lu: 1 } : msg));
        
            try {
                await axios.post(`${adresseServeurBackEnd}/decrementeNbreMessageNonLu`, {
                    employeId: fichePersonnel.id,
                    headers: {
                        Authorization: `Bearer ${fichePersonnel.token}`,
                        'Content-Type': 'application/json'
                    }
                });
            } catch (error) {
                console.error('Erreur lors de la mise à jour du statut de lecture du message:', error);
            }
            fetchMessages();
        } 
    };


    return (
        <div className='container-boite-reception'>
            <h2>Boîte de Réception</h2>
            <div className='liste-messages'>
                {messages.map((message) => (
                    <div key={message.id} className='message-item'
                        onClick={() => handleClickMessage(message)}>
                        <div className={message.lu ? 'message-objet-lu':'message-objet-non-lu'}>
                            {message.objetMessage}
                        </div>
                        <div className= 'message-info-date'>
                            {new Date(message.dateCreation).toLocaleString()} de 
                            <span>{message.auteur}</span>
                        </div>
                    </div>
                ))}
            </div>
            {selectedMessage && (
                <div className='message-details'>
                    <h3>Message de {selectedMessage.auteur}</h3>
                    <p>{selectedMessage.textMessage}</p>
                    <button onClick={() => setSelectedMessage(null)}>Fermer</button>
                </div>
            )}
        </div>
    );
}

export default Reception