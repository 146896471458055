
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './gestionEmployes.css';
import { useMonContexte } from '../../MonContext';
import GestionFonction from './GestionFonction';
import AjouterFonctionPersonnel from './AjouterFonctionPersonnel';
import { SketchPicker } from 'react-color'; // Importer SketchPicker depuis react-color
import GestionEdt from './GestonEdt';

const GestionEmployes = () => {
  const { adresseServeurBackEnd, setContenuPageCentrale, fichePersonnel } = useMonContexte();

  const [employees, setEmployees] = useState([]);
  const [form, setForm] = useState({
    id: null,
    nom: '',
    prenom: '',
    username: '',
    password: '',
    fonction: '',
    couleurEDT: '#FFFFFF', // Ajouter la couleur par défaut (blanc par exemple)
  });
  const [error, setError] = useState('');
  const [visibleFormAjouter, setVisibleFormAjouter] = useState(false);
  const [visibleAjouterFonction, setVisibleAjouterFonction] = useState(false);
  const [visibleGestionFonction, setVisibleGestionFonction] = useState(false);
  const [visibleListePersonnel, setVisibleListePersonnel] = useState(true);
  const [roles, setRoles] = useState([]);

  const fetchRoles = async () => {
    try {
      const response = await axios.get(`${adresseServeurBackEnd}/menu-permissions`,
      {headers:{ Authorization: `Bearer ${fichePersonnel.token}`}
    });
      if (Array.isArray(response.data)) {
        setRoles(response.data);
      } else {
        throw new Error('La réponse de l\'API n\'est pas un tableau');
      }
    } catch (error) {
      setError('Erreur lors du chargement des rôles');
      console.error('Erreur lors du chargement des rôles', error);
    }
  };

  const fetchEmployees = async () => {
    try {
        const response = await axios.get(`${adresseServeurBackEnd}/employes`,
        {headers:{ Authorization: `Bearer ${fichePersonnel.token}`}
        });
      if (Array.isArray(response.data)) {
        setEmployees(response.data);
      } else {
        setError('La réponse de l\'API n\'est pas un tableau');
        console.error('La réponse de l\'API n\'est pas un tableau', response.data);
      }
    } catch (error) {
      setError('Erreur lors du chargement des employés');
      console.error('Erreur lors du chargement des employés', error);
    }
  };

  useEffect(() => {
    fetchEmployees();
    fetchRoles();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({ ...prevForm, [name]: value }));
  };

  const handleColorChange = (color) => {
    console.log('ChangeColor: ',color)
    setForm((prevForm) => ({ ...prevForm, couleurEDT: color.hex }));
  };

  const handleSubmit = async (e) => {
    console.log('SubmitColor: ', form)
    e.preventDefault();
    try {
      if (form.id) {
        await axios.put(`${adresseServeurBackEnd}/employes/${form.id}`, form, {
          headers: {
              Authorization: `Bearer ${fichePersonnel.token}`,
              'Content-Type': 'application/json'
          }
      });
      } else {
        await axios.post(`${adresseServeurBackEnd}/employes`, form, {
          headers: {
              Authorization: `Bearer ${fichePersonnel.token}`,
              'Content-Type': 'application/json'
          }
      });
      }
      setForm({ id: null, nom: '', prenom: '', username: '', password: '', fonction: '', 
                couleurEDT: '#FFFFFF' });
      fetchEmployees();
    } catch (error) {
      setError('Erreur lors de l\'enregistrement de l\'employé');
      console.error('Erreur lors de l\'enregistrement de l\'employé', error);
    }
    handleBasculeFormPersonnel(true);
  };

  const handleEdit = (employee) => {
    setVisibleFormAjouter(true);
    setForm(employee);
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${adresseServeurBackEnd}/employes/${id}`, {
        headers: {
            Authorization: `Bearer ${fichePersonnel.token}`,
            'Content-Type': 'application/json'
        }
        
    });
      console.log(`${adresseServeurBackEnd}/employes/${id}`);
      console.log('token: ', fichePersonnel.token);
      fetchEmployees();
    } catch (error) {
      setError('Erreur lors de la suppression de l\'employé');
      console.error('Erreur lors de la suppression de l\'employé', error);
    }
  };

  const handleBasculeFormAjoutFonction = (bool) => {
    if (bool) {
      setVisibleAjouterFonction(bool);
      setVisibleFormAjouter(!bool);
      setVisibleGestionFonction(!bool);
      setVisibleListePersonnel(!bool);
    } else {
      setVisibleAjouterFonction(bool);
      setVisibleFormAjouter(bool);
      setVisibleGestionFonction(bool);
      setVisibleListePersonnel(!bool);
    }
  };

  const handleBasculeFormAjout = (bool) => {
    if (bool) {
      setVisibleFormAjouter(bool);
      setVisibleGestionFonction(!bool);
      setVisibleAjouterFonction(!bool);
      setVisibleListePersonnel(!bool);
    } else {
      setVisibleAjouterFonction(bool);
      setVisibleFormAjouter(bool);
      setVisibleGestionFonction(bool);
      setVisibleListePersonnel(!bool);
    }
  };

  const handleBasculeFormPersonnel = (bool) => {
    if (bool) {
      setVisibleListePersonnel(bool);
      setVisibleFormAjouter(!bool);
      setVisibleGestionFonction(!bool);
      setVisibleAjouterFonction(!bool);
    } else {
      setVisibleAjouterFonction(bool);
      setVisibleFormAjouter(bool);
      setVisibleGestionFonction(bool);
      setVisibleListePersonnel(!bool);
    }
  };

  const handleBasculeGestionFonction = (bool) => {
    if (bool) {
      setVisibleGestionFonction(bool);
      setVisibleFormAjouter(!bool);
      setVisibleListePersonnel(!bool);
      setVisibleAjouterFonction(!bool);
    } else {
      setVisibleAjouterFonction(bool);
      setVisibleFormAjouter(bool);
      setVisibleGestionFonction(bool);
      setVisibleListePersonnel(!bool);
    }
  };

  return (
    <div className="employees-manager">
      <button onClick={()=>setContenuPageCentrale(<GestionEdt />)} >Gestion EDT</button>
      <h1>Gestion des Personnels</h1>
      {error && <p className="error">{error}</p>}

      <div>
        {visibleAjouterFonction && (
          <AjouterFonctionPersonnel
            fetchRoles={fetchRoles}
            handleBasculeFormAjoutFonction={handleBasculeFormAjoutFonction}
            handleBasculeFormPersonnel={handleBasculeFormPersonnel}
          />
        )}
      </div>
      {visibleFormAjouter && (
        <form className="employees-form" onSubmit={handleSubmit}>
          <input
            type="text"
            name="nom"
            placeholder="Nom"
            value={form.nom}
            onChange={handleChange}
            required
          />
          <input
            type="text"
            name="prenom"
            placeholder="Prénom"
            value={form.prenom}
            onChange={handleChange}
            required
          />
          <input
            type="text"
            name="username"
            placeholder="Nom d'utilisateur"
            value={form.username}
            onChange={handleChange}
            required
          />
          <input
            type="password"
            name="password"
            placeholder="Mot de passe"
            value={form.password}
            onChange={handleChange}
            minLength={6}
          />
          <label>
            <select
              className="selection-fonction"
              name="fonction"
              value={form.fonction}
              onChange={handleChange}
              required
            >
              <option value="" disabled>
                Choisir une fonction
              </option>
              {roles.map((role) => (
                <option key={role.id} value={role.role}>
                  {role.role}
                </option>
              ))}
            </select>
          </label>
          <div className="color-picker">
            <label>Sélectionner une couleur pour l'EDT :</label>
            <SketchPicker
              color={form.couleurEDT}
              onChangeComplete={handleColorChange}
            />
          </div>
          <div className="btn-ajouter-employe">
            <button className="btn" type="submit">
              {form.id ? 'Modifier' : 'Ajouter'}
            </button>
            <button className="btn" onClick={() => handleBasculeFormAjout(false)}>
              Annuler
            </button>
          </div>
        </form>
      )}
      {visibleListePersonnel && (
        <div>
          <div className="btn-gestion-personnel">
            <button
              hidden={visibleFormAjouter}
              onClick={() => handleBasculeFormAjout(true)}
            >
              Ajouter un Personnel
            </button>
            <button
              hidden={visibleFormAjouter}
              onClick={() => handleBasculeFormAjoutFonction(true)}
            >
              Ajouter une fonction
            </button>
            <button
              hidden={visibleGestionFonction}
              onClick={() => handleBasculeGestionFonction(true)}
            >
              Modifier le Menu
            </button>  
          </div>
          <div className="container-personnel-table">
            <table className="employees-table">
              <thead>
                <tr>
                  <th>Nom</th>
                  <th>Prénom</th>
                  <th>Username</th>
                  <th>Fonction</th>
                  <th>Couleur</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {employees.map((employee) => (
                  <tr key={employee.id}>
                    <td>{employee.nom}</td>
                    <td>{employee.prenom}</td>
                    <td>{employee.username}</td>
                    <td>{employee.fonction}</td>
                    <td><div className='couleur-personnel' style={{backgroundColor:employee.couleurEDT}} ></div> </td>
                    <td className="btn-ajouter-prestation">
                      <button
                        className="edit-button"
                        onClick={() => handleEdit(employee)}
                      >
                        Modifier
                      </button>
                      <button
                        className="delete-button"
                        onClick={() => handleDelete(employee.id)}
                      >
                        Supprimer
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div> 
        </div>)}

       { visibleGestionFonction &&  <GestionFonction handleBasculeGestionFonction={handleBasculeGestionFonction}/>}
       
      
    </div>
  );
};

export default GestionEmployes;


