
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useMonContexte } from '../../MonContext';
import './gestionObjectifs.css';

const GestionObjectifs = () => {
  const { adresseServeurBackEnd, objectifs, setObjectifs, fichePersonnel } = useMonContexte();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [infoGestionObjectif, setInfoGestionObjectif] = useState('');
  const [objectifInstitut, setObjectifInstitut] = useState(0.0);

  const moisOptions = [
    'janvier', 'février', 'mars', 'avril', 'mai', 'juin',
    'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'
  ];
  
  
  function obtenirMoisActuels() {  
    const dateActuelle = new Date();
    const mois = moisOptions[dateActuelle.getMonth()];    
    return mois;
  };

  function obtenirAnneeActuels() {  
    const dateActuelle = new Date();
    const annee = dateActuelle.getFullYear().toString();     
    return annee;
  };

  
  const [mois, setMois] = useState(obtenirMoisActuels());
  const [annee, setAnnee] = useState(obtenirAnneeActuels());

  const anneesOptions = Array.from({ length: 10 }, (_, i) => (new Date().getFullYear() - i).toString());

  const fetchObjectifs = async () => {
    setLoading(true);
    setError('');
    try {
      const response = await axios.get(`${adresseServeurBackEnd}/objectifsEmployes/${annee}/${mois}`, {
        headers: { Authorization: `Bearer ${fichePersonnel.token}` }
    });
      setObjectifs(response.data.objectifs);
      setLoading(false);
    } catch (error) {
      console.error('Erreur lors de la récupération des employés:', error);
      setError('Erreur lors de la récupération des employés.');
      setLoading(false);
    }
  };

  const calculObjectifInstitut = () => {
    return objectifs.reduce((total, employe) => {
      return total + calculateTotal(employe.valeurs);
    }, 0);
  }



  useEffect(() => {
    fetchObjectifs();
    setObjectifInstitut(calculObjectifInstitut().toFixed(2))
  }, [adresseServeurBackEnd, annee, mois]);

  const handleChangeObjectif = (employeId, index, event) => {
    setInfoGestionObjectif('');
    const newObjectifs = objectifs.map((employe) =>
      employe.employeId === employeId
        ? { ...employe, valeurs: employe.valeurs.map((valeur, i) => (i === index ? event.target.value : valeur)) }
        : employe
    );
    setObjectifs(newObjectifs);
  };

  const calculateTotal = (valeurs) => {
    return valeurs.reduce((total, valeur) => total + parseFloat(valeur || 0), 0);
  };

  const handleRecupererValeurs = async () => {
    const data = { annee, mois, objectifs };
    try {
      const response = await axios.post(`${adresseServeurBackEnd}/ajouterObjectifsMensuels`, data, {
        headers: {
            Authorization: `Bearer ${fichePersonnel.token}`,
            'Content-Type': 'application/json'
        }
    });
      setInfoGestionObjectif('Objectifs mensuels ajoutés avec succès.');
    } catch (error) {
      console.error("Erreur lors de l'ajout des objectifs mensuels:", error);
      setInfoGestionObjectif("Erreur lors de l'ajout des objectifs mensuels. Veuillez réessayer.");
    }
    fetchObjectifs();
  };

  const handleChangeMois = (e) => {
    setInfoGestionObjectif('');
    setMois(e.target.value);
  };

  const handleChangeAnnee = (e) => {
    setInfoGestionObjectif('');
    setAnnee(e.target.value);
  };



  if (loading) {
    return <div>Chargement...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className='objectifs-manager'>
      <h2>Gestion des objectifs des employés</h2>
      <div className='bloc-annee-mois-objectif'>
        <div className='mois-objectif'>
          Mois: 
          <select value={mois} onChange={(e) => handleChangeMois(e)}>
            {moisOptions.map((mois) => (
              <option key={mois} value={mois}>{mois}</option>
            ))}
          </select>
        </div>
        <div className='annee-objectif'>
          Année: 
          <select value={annee} onChange={(e) => handleChangeAnnee(e)}>
            {anneesOptions.map((annee) => (
              <option key={annee} value={annee}>{annee}</option>
            ))}
          </select>
        </div>
        <div className='objectifs-institut'>
          Objectif du Mois: <span className='montant'> {objectifInstitut}€ </span>
        </div>
      </div>
      <div className='container-objectifs-table'>
        <table className='objectif-table'>
          <thead>
            <tr>
              <th>Nom Prénom</th>
              <th>Semaine 1</th>
              <th>Semaine 2</th>
              <th>Semaine 3</th>
              <th>Semaine 4</th>
              <th>Total mensuel</th>
            </tr>
          </thead>
          <tbody>
            {objectifs.map((employe) => (
              <tr key={employe.employeId}>
                <td>{employe.nom} {employe.prenom}</td>
                {employe.valeurs.map((valeur, index) => (
                  <td key={index}>
                    <input
                      type="text"
                      value={valeur}
                      onChange={(e) => handleChangeObjectif(employe.employeId, index, e)}
                    />
                  </td>
                ))}
                <td>{calculateTotal(employe.valeurs).toFixed(2)} €</td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className='btn-objectifs'>
          <button onClick={handleRecupererValeurs}>Sauvegarder les Objectifs</button>
        </div>
        {infoGestionObjectif}
      </div>
    </div>
  );
};

export default GestionObjectifs;










/*
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useMonContexte } from './MonContext';
import './gestionObjectifs.css';

const GestionObjectifs = () => {
  const { adresseServeurBackEnd, fichePersonnel } = useMonContexte();

  const toto = {
    employeId: 1,
    nom: 'TAYLOR',
    prenom: 'Alyce',
    valeurs: ['500.00', '600.00', '550.00', '580.00'],
  };

  const [mois, setMois] = useState('');
  const [annee, setAnnee] = useState('');
  const [objectifs, setObjectifs] = useState([toto]);
  const [objectifsMensuel, setObjectifsMensuel] = useState([]);
  const [totalMensuel, setTotalMensuel] = useState(0);

  const handleChangeObjectif = (employeId, index, event) => {
    const newObjectifs = [...objectifs];
    const employe = newObjectifs.find(emp => emp.employeId === employeId);
    if (employe) {
      employe.valeurs[index] = event.target.value;
      setObjectifs(newObjectifs);
    }
  };

  useEffect(() => {
    const fetchEmployes = async () => {
      try {
        const response = await axios.get(`${adresseServeurBackEnd}/objectifsEmployes/2023/novembre`);
        setObjectifs(response.data.objectifs);
        setMois(response.data.mois);
        setAnnee(response.data.annee);
      } catch (error) {
        console.error('Erreur lors de la récupération des employés:', error);
      }
    };

    fetchEmployes();
  }, [adresseServeurBackEnd]);

  const calculateTotal = (valeurs) => {
    return valeurs.reduce((total, valeur) => total + parseFloat(valeur), 0).toFixed(2);
  };

 

  const handleRecupererValeurs = async () => {
    const data = {
      annee,
      mois,
      objectifs
    };

    try {
      const response = await axios.post(`${adresseServeurBackEnd}/ajouterObjectifsMensuels`, data);
      console.log('Objectifs mensuels ajoutés avec succès:', response.data);
      alert('Objectifs mensuels ajoutés avec succès.');
    } catch (error) {
      console.error('Erreur lors de l\'ajout des objectifs mensuels:', error);
      alert('Erreur lors de l\'ajout des objectifs mensuels. Veuillez réessayer.');
    }
  };

  return (
    <div className='objectifs-manager'>
      <h2>Gestion des objectifs des employés</h2>
      <div className='bloc-annee-mois-objectif'>
          <div className='mois-objectif'>
              Mois: <input  
                            type="text"
                            value={mois}
                    /> 
          </div> 
          <div className='annee-objectif'>   
                Année:<input  
                              type="text"
                              value={annee}
                      /> 
          </div>
      </div>
      <div className='container-objectifs-table'>
        <table className='objectif-table'>
          <thead>
            <tr>
              <th>Nom Prénom</th>
              <th>Semaine 1</th>
              <th>Semaine 2</th>
              <th>Semaine 3</th>
              <th>Semaine 4</th>
              <th>Total mensuel</th>
            </tr>
          </thead>
          <tbody>
            {objectifs.map((employe) => (
              <tr key={employe.employeId}>
                <td>{employe.nom} {employe.prenom}</td>
                {employe.valeurs.map((valeur, index) => (
                  <td key={index}>
                    <input  
                      type="text"
                      value={valeur}
                      onChange={(e) => handleChangeObjectif(employe.employeId, index, e)}
                    />
                  </td>
                ))}
                <td>{calculateTotal(employe.valeurs)}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className='btn-objectifs'>
          <button onClick={handleRecupererValeurs}>Sauvegarder les Objectifs</button>
        </div>
      </div>
    </div>
  );
};

export default GestionObjectifs;
*/

