import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './gestionPrestations.css';
import { useMonContexte } from '../../MonContext';

const GestionPrestations = () => {

  const {adresseServeurBackEnd, fichePersonnel} =  useMonContexte();

    const [prestations, setPrestations] = useState([]);
    const [form, setForm] = useState({
      id: null,
      nom: '',
      description: '',
      duree: '',
      prix: ''
    });

    const [error, setError] = useState('');
    const [visibleFormAjouter, setVisibleFormAjouter] = useState(false);
  
    useEffect(() => {
      fetchPrestations();
    }, []);
  
    const fetchPrestations = async () => {
      try {
        const response = await axios.get(`${adresseServeurBackEnd}/prestations`,
        {headers:{ Authorization: `Bearer ${fichePersonnel.token}`}
        });
        if (Array.isArray(response.data)) {
          setPrestations(response.data);
        } else {
          setError('La réponse de l\'API n\'est pas un tableau');
          console.error('La réponse de l\'API n\'est pas un tableau', response.data);
        }
      } catch (error) {
        setError('Erreur lors du chargement des prestations');
        console.error('Erreur lors du chargement des prestations', error);
      }
    };
  
    const handleChange = (e) => {
      setError('');
      const { name, value } = e.target;
      setForm((prevForm) => ({ ...prevForm, [name]: value }));
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      setVisibleFormAjouter(false)
      try {
        if (form.id) {
          await axios.put(`${adresseServeurBackEnd}/prestations/${form.id}`, form, {
            headers: {
                Authorization: `Bearer ${fichePersonnel.token}`,
                'Content-Type': 'application/json'
            }
          });
        } else {
          await axios.post(`${adresseServeurBackEnd}/prestations`, form, {
            headers: {
                Authorization: `Bearer ${fichePersonnel.token}`,
                'Content-Type': 'application/json'
            }
          });
        }
        setForm({ id: null, nom: '', description: '', duree: '', prix: '' });
        fetchPrestations();
      } catch (error) {
        setError('Erreur lors de l\'enregistrement de la prestation');
        console.error('Erreur lors de l\'enregistrement de la prestation', error);
      }
    };
  
    const handleEdit = (prestation) => {
        setVisibleFormAjouter(true)
        setForm(prestation);
    };
  
    const handleDelete = async (id) => {
      try {
        await axios.delete(`${adresseServeurBackEnd}/prestations/${id}`, {
          headers: {
              Authorization: `Bearer ${fichePersonnel.token}`,
              'Content-Type': 'application/json'
          }
      });
        fetchPrestations();
      } catch (error) {
        setError('Erreur lors de la suppression de la prestation');
        console.error('Erreur lors de la suppression de la prestation', error);
      }
    };
    
    const handleFermeForm = () => {
      setVisibleFormAjouter(false);
    }
  
    return (
      <div className="prestations-manager">
            <h1>Gestion des Prestations</h1>
            {error && <p className="error">{error}</p>}
            <div className='btn-ajouter-prestation'>
                <button hidden={visibleFormAjouter} onClick={()=>{setVisibleFormAjouter(true);setError('');}}>
                    Saisir une prestation</button>
            </div>
             { visibleFormAjouter && <form className="prestations-form" onSubmit={handleSubmit}>
            <input
                type="text"
                name="nom"
                placeholder="Nom"
                value={form.nom}
                onChange={handleChange}
                required
            />
            <textarea
                name="description"
                placeholder="Description"
                value={form.description}
                onChange={handleChange}
            ></textarea>
            <input
                type="number"
                name="duree"
                placeholder="Durée (minutes)"
                value={form.duree}
                onChange={handleChange}
                required
            />
            <input
                type="number"
                step="0.01"
                name="prix"
                placeholder="Prix (€)"
                value={form.prix}
                onChange={handleChange}
                required
            />
            <div className='btn-gestion-prestation'>
                <button type="submit">{form.id ? 'Modifier' : 'Ajouter'}</button>
                <button onClick={()=> handleFermeForm()} >Annuler</button>
            </div>
            </form>}
            { !visibleFormAjouter && <div className='container-prestations-table'>
            <table className="prestations-table">
            <thead>
                <tr>
                <th>Nom</th>
                <th>Description</th>
                <th>Durée </th>
                <th>Prix </th>
                <th>Actions</th>
                </tr>
            </thead>
            <tbody>
                {prestations.map((prestation) => (
                <tr key={prestation.id}>
                    <td>{prestation.nom}</td>
                    <td>{prestation.description}</td>
                    <td>{prestation.duree} min</td>
                    <td>{prestation.prix} €</td>
                    <td>
                    <button className="edit-button" onClick={() => handleEdit(prestation)}>Modifier</button>
                    <button className="delete-button" onClick={() => handleDelete(prestation.id)}>Supprimer</button>
                    </td>
                </tr>
                ))}
            </tbody>
            </table>
      </div>}
      </div>
    );
}

export default GestionPrestations
