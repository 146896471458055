import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './saisieRdv.css';
import { useMonContexte } from '../../MonContext';
import 'moment/locale/fr';
import Calendrier from '../Calendrier/Calendrier';
import FicheClient from '../Client/FicheClient';

const PriseRDVClient = () => {
    const { adresseServeurBackEnd, ficheClient, setContenuPageCentrale, fichePersonnel } = useMonContexte();
  
    const [formData, setFormData] = useState( {
      nomPrenom: `${ficheClient.nom}-${ficheClient.prenom}`,
      employeId: '',
      dateHeure: '',
      telephone: ficheClient.telephone,
      prestations: [],
      heureFin: '',
      status: 'Scheduled',
      isClient: 1
    });
  
    const [choixPresta, setChoixPresta] = useState([]);
    const [prixTotal, setPrixTotal] = useState(0);
    const [dureeTotalePresta, setDureeTotalePresta] = useState(0);
    const [prestations, setPrestations] = useState([]);
    const [prestaId, setPrestaId] = useState([]);
    const [employesDisponibles, setEmployesDisponibles] = useState([]);
    const [error, setError] = useState('');
  
    const fetchPrestations = async () => {
      try {
        const response = await axios.get(`${adresseServeurBackEnd}/prestations`, {
          headers: { Authorization: `Bearer ${fichePersonnel.token}` }
      });
        setPrestations(response.data);
      } catch (error) {
        console.error('Erreur lors de la récupération des prestations:', error);
      }
    };
  
    const fetchDisponibiliteEmploye = async () => {
      try {
        const response = await axios.get(`${adresseServeurBackEnd}/disponibilite`, {
          headers: { Authorization: `Bearer ${fichePersonnel.token}` },
          params: {
              dateHeure: formData.dateHeure,
              duree: dureeTotalePresta
          }
        });
        setEmployesDisponibles(response.data);
        console.log('Dispo: ', response.data)
      } catch (error) {
        console.error('Erreur lors de la récupération des disponibilites:', error);
      }
    };
  
    useEffect(() => {
      console.log(formData)
      fetchPrestations();
    }, []);
  
    useEffect(() => {
      fetchDisponibiliteEmploye();
    }, [dureeTotalePresta, formData.dateHeure]);
  
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setError('');
      if (name === 'dateHeure') {
        const dateHeure = new Date(value);
        setFormData({ ...formData, [name]: dateHeure });
      } else {
        setFormData({ ...formData, [name]: value });
      }
    };
  
    const handleClick = (prestation) => {
      setError('');
      const prestationData = {
        id: prestation.id,
        nom: prestation.nom,
        duree: prestation.duree,
        prix: prestation.prix
      };
      const nouvelleDureeTotale = dureeTotalePresta + prestation.duree;
      setDureeTotalePresta(nouvelleDureeTotale);
      setChoixPresta([...choixPresta, prestationData]);
      setPrixTotal(prixTotal + parseFloat(prestation.prix));
      const nouveauxIdsPrestations = [...prestaId, prestation.id];
      setPrestaId(nouveauxIdsPrestations);
      const dateHeure = new Date(formData.dateHeure);
      const heureFin = new Date(dateHeure.getTime() + nouvelleDureeTotale * 60000);
      setFormData({
        ...formData,
        heureFin: formatDateTimeLocal(heureFin),
        prestations: nouveauxIdsPrestations,
        status: 'Scheduled'
      })
    }
  
    useEffect(() => {
      const dateHeure = new Date(formData.dateHeure);
      const heureFin = new Date(dateHeure.getTime() + dureeTotalePresta * 60000);
      setFormData({
        ...formData,
        heureFin: formatDateTimeLocal(heureFin),
        prestations: prestaId
      });
    }, [prestaId, dureeTotalePresta]);
  
    const handleClickClear = () => {
      setError('');
      setDureeTotalePresta(0);
      setPrixTotal(0);
      setChoixPresta([]);
      setPrestaId([]);
    };
  
    const handleAnnule = () => {
      setContenuPageCentrale(<FicheClient />);
    }
  
   
    const handleSubmit = async (e) => {
      e.preventDefault();
      console.log(formData);
  
      try {
        const formattedData = {
          ...formData,
          dateHeure: new Date(formData.dateHeure).toISOString().slice(0, 19).replace('T', ' '),
          heureFin: new Date(formData.heureFin).toISOString().slice(0, 19).replace('T', ' '),
          isClient: 1
        };
        console.log(formattedData.isClient);
        const response = await axios.post(`${adresseServeurBackEnd}/rendezvous`, formattedData, {
          headers: {
              Authorization: `Bearer ${fichePersonnel.token}`,
              'Content-Type': 'application/json'
          }
      });
        console.log('Rendez-vous créé:', response.data);
        setContenuPageCentrale(<Calendrier />);
      } catch (error) {
        console.error('Erreur lors de la création du rendez-vous:', error);
        setError('Erreur lors de la création du rendez-vous');
      }
    };
  
    const formatDateTimeLocal = (date) => {
      const d = new Date(date);
      const year = d.getFullYear();
      const month = String(d.getMonth() + 1).padStart(2, '0');
      const day = String(d.getDate()).padStart(2, '0');
      const hours = String(d.getHours()).padStart(2, '0');
      const minutes = String(d.getMinutes()).padStart(2, '0');
      return `${year}-${month}-${day}T${hours}:${minutes}`;
    };
  
    return (
      <div className="saisie-rdv-container">
        <h2>Ajouter un Rendez-vous</h2>
        {error && <p style={{ color: 'red' }}>{error}</p>}
        <form onSubmit={handleSubmit}>
          <div className='bloc-identité-rdv'>
            <label className='input-label-rdv'>
              Nom-Prénom :
              <input id='input-nomprenom'
                type="text"
                placeholder="Nom Prénom"
                name="nomPrenom"
                value={formData.nomPrenom}
                onChange={handleInputChange}
                required
              />
            </label>
            <label className='input-label-rdv'>
              Téléphone:
              <input className='input-label-rdv'
                type="text"
                placeholder="Téléphone"
                name="telephone"
                value={formData.telephone}
                onChange={handleInputChange}
                required
              />
            </label>
          </div>
          <label>
            Date et Heure:
            <input
              type="datetime-local"
              name="dateHeure"
              value={formatDateTimeLocal(new Date(formData.dateHeure))}
              onChange={handleInputChange}
              required
            />
          </label>
          
          <div className='bloc-prestation'>
            <p>Choisissez vos prestations:</p>
            <div className='label-presta-choix'>
              <label className='label-presta'>              
                <select name="prestations" multiple value={formData.prestations} readOnly>
                  {prestations.map((prestation) => (
                    <option key={prestation.id} value={prestation.id} onClick={() => handleClick(prestation)}>
                      {prestation.nom}
                    </option>
                  ))}
                </select>
              </label>
              <div className='choix-presta'>
                <div className='choix-ul-li'>
                  <ul>
                    {choixPresta.map((choix, index) => (
                      <li key={index}>
                        {choix.nom} {choix.duree} min
                      </li>
                    ))}
                  </ul>  
                </div>
              </div> 
            </div>      
            <div className='choix-totaux'>
              Prix total: {prixTotal}€ &nbsp; Durée total: {dureeTotalePresta} min
            </div>
            
            <div className='btn-choix-presta'>
              <button type="button" onClick={handleClickClear}>Clear</button>
            </div>
          </div>
          <label>
            Heure de Fin:
            <input
              type="datetime-local"
              name="heureFin"
              value={formatDateTimeLocal(new Date(formData.dateHeure ? new Date(formData.dateHeure).getTime() + dureeTotalePresta * 60000 : new Date().getTime()))}
              readOnly
            />
          </label>
          <label>
            Employé:
            <select
              name="employeId"
              value={formData.employeId}
              onChange={handleInputChange}
              required
            >
              <option value="">Sélectionner un employé</option>
              {employesDisponibles.map((employe) => (
                <option key={employe.id} value={employe.id}>
                  {employe.prenom}
                </option>
              ))}
            </select>
          </label>
          <div className="btn-form-rdv">
            <button type="submit">Enregistrer</button>
            <button type="button" onClick={handleAnnule}>Annuler</button>
          </div>
        </form>
      </div>
    );
  };
  
export default PriseRDVClient