import React from 'react';
import '../App.css';
import NavBar from './NavBar';
import salon from '../assets/salon.jpg'
import PiedPage from './PiedPage';

const Presentation = () => {
  return (
    <div className="presentation">
        <NavBar/>
        <div className='bloc-apropos'>
              <h2>À Propos de Nous</h2>
              <div className='bloc-presentation-salon'>
                  <p>Situé à quelques pas du célebre phare de Mahina, L'équipe de Tiare Barber Shop 
                      vous accueillera dans un cadre convivial</p>
                  <p>Chez Tiare Barbershop, c'est l'endroit idéal pour une coupe de cheveux élégante et moderne.</p>
              </div>
        </div>
        <div className='img-presentation'>
          <img src={salon} alt="Intérieur du salon" className="salon-image"/>
        </div>
        <div className='bloc-equipe'>
              <h3>Notre Équipe</h3>
              <ul>
                  <li><strong>Teiva</strong> Notre manager, 12 ans d'expérience, Lauréat du concours national de coiffure PARIS 2017</li>
                  <li><strong>Manutahi</strong> Notre responsable - 8 ans d'expérience, Spécialiste en coiffures modernes</li>
                  <li><strong>Heremona</strong> Notre employé, 5 ans d'expérience, Diplômé de l'école de coiffure internationale de Tahiti</li>
                  <li><strong>Hina</strong> Notre apprentié en coiffure masculine</li>
                  <li><strong>Arii</strong> Notre stagiaire du lycée professionnel de Mahina</li>
              </ul>
        </div>
        <PiedPage/>
    </div>
  );
};

export default Presentation;
