
import React, { useEffect, useState, useCallback } from 'react';
import './infoRdv.css';
import { useMonContexte } from '../../MonContext';
import AffichageClientSelectionnee from '../PageTemplete/AffichageClientSelectionnee';
import SaisieRendezVous from '../RendezVous/SaisieRendezVous';
import axios from 'axios';
import FicheClient from '../Client/FicheClient';

const InfoRdv = ({ rendezVous }) => {
  const { adresseServeurBackEnd, setBasculeRDVClient, setContenuPageCentrale, setContenuPageDroite, 
    setFicheClient, fichePersonnel } = useMonContexte();

  const [nom, setNom] = useState('');
  const [date, setDate] = useState('');
  const [debut, setDebut] = useState('');
  const [fin, setFin] = useState('');
  const [telephone, setTelephone] = useState('');
  const [prestation, setPrestation] = useState([]);
  const [ficheTempo, setFicheTempo] = useState([]);
  const [employe, setEmploye] = useState('');

  const transformationRendezVous = useCallback((rdv) => {
    setNom(rdv.nomPrenom);
    const timeDebut = new Date(rdv.dateHeure).toLocaleString('fr-FR');
    const timeFin = new Date(rdv.heureFin).toLocaleString('fr-FR');
    setDate(timeDebut.slice(0, 10));
    setDebut(timeDebut.slice(11, 16).replace(':', 'H'));
    setFin(timeFin.slice(11, 16).replace(':', 'H'));
    setPrestation(rdv.prestations || []);
    setTelephone(rdv.telephone);
    setEmploye(rdv.prenomEmploye);
  }, []);

  const rechercheFicheClient = async (telephone) => {
    try {
      const response = await axios.get(`${adresseServeurBackEnd}/clients/search?term=${encodeURIComponent(telephone)}`,{
        headers:{Authorization: `Bearer ${fichePersonnel.token}`}
        });
      if (response.data.length > 0) {
        setFicheClient(response.data[0]);
      } else {
        console.log('Aucun client trouvé pour ce numéro de téléphone.');
      }
    } catch (error) {
      console.error('Erreur lors de la recherche du client:', error);
    }
  };

  useEffect(() => {
    transformationRendezVous(rendezVous);
    setBasculeRDVClient(true);
    rechercheFicheClient(rendezVous.telephone);
  }, [rendezVous, transformationRendezVous, rechercheFicheClient, setBasculeRDVClient]);

  const handleClickFicheClient = useCallback(() => {
    setContenuPageCentrale(<FicheClient/>)
  }, [ficheTempo, setContenuPageDroite]);

  const handleClickCreationClient = useCallback(() => {

    setContenuPageCentrale(<FicheClient/>)
  }, []);

  const handleClickModifierRdv = useCallback(() => {
    setContenuPageCentrale(<SaisieRendezVous modeModification={true} initialData={rendezVous} />);
  }, [rendezVous, setContenuPageCentrale]);

  const handleClickSupprimerRdv = useCallback(() => {
    setContenuPageCentrale(<SaisieRendezVous modeModification={true} initialData={rendezVous} />);
  }, [rendezVous, setContenuPageCentrale]);

  return (
    <div className='bloc-rdv-info'>
      <h2>Informations RDV:</h2>
      <fieldset>
        <legend className='nom-rdv'>
          {nom}
        </legend>

        <h3 className='bloc-info-field'>Rendez-vous:</h3>
        <div className='horaire-rdv'>
            <div className='bloc-date'>
              Le {date}
            </div>
            <div className='bloc-debut-fin'>
              De {debut} à {fin}
            </div>
        </div>

        <h3 className='bloc-info-field'>Prestations:</h3>
        <div className='presta-rdv'>
          <ul>
            {prestation.map((presta, index) => (
              <li key={index}>{presta.nom} <br/> <span className='span-presta-rdv-info' >{presta.duree}min &nbsp; {presta.prix}€</span> </li>
            ))}
          </ul>
        </div>
        <h3 className='bloc-info-field'>Téléphone:</h3>
        <div className='telephone-rdv'>
          {telephone}
        </div>
        <h3 className='bloc-info-field'>Prothésiste:</h3>
        <div className='prothesiste-rdv' style={{ color: rendezVous.employeCouleur }}>
          {employe}
        </div>
      </fieldset>
      <div className='bloc-btn-rdv'>
        {rendezVous.isClient ? (
          <div className='btn-action-info-rdv'>
            <button onClick={handleClickFicheClient}>Ouvrir la fiche Client</button>
          </div>
        ) : (
          <div className='btn-action-info-rdv'>
            <button onClick={handleClickCreationClient}>Créer la fiche Client</button>
          </div>
        )}

        <div className='btn-action-info-rdv'>
          <button onClick={handleClickModifierRdv}>Modifier le RDV</button>
        </div>
        <div className='btn-action-info-rdv'>
          <button onClick={handleClickSupprimerRdv}>Supprimer le RDV</button>
        </div>
      </div>
    </div>
  );
}

export default InfoRdv;



